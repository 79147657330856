import React, { useContext } from "react";
import AuthenLayout from "./AuthenLayout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import { AlertContext } from "../context/AlertContext";
import { AuthenContext } from "../context/AuthenContext";

const MainLayout = ({ children }) => {
	let location = useLocation();
	const { alerts } = useContext(AlertContext);
	const { user, logout } = useContext(AuthenContext);
	const navigate = useNavigate();
	return (
		<AuthenLayout>
			{alerts.map(
				(alert, idx) =>
					alert.visible && (
						<Alert
							key={idx}
							type={alert.type}
							message={alert.message}
							status={alert.status}
							title={alert.title}
						/>
					)
			)}
			<div
				ng-controller="secondNavbar"
				className="navbar navbar-inverse ng-scope"
				id="navbar-second"
			>
				<ul className="nav navbar-nav no-border visible-xs-block">
					<li>
						<a
							className="text-center collapsed"
							data-toggle="collapse"
							data-target="#navbar-second-toggle"
						>
							<i className="icon-menu7"></i>
						</a>
					</li>
				</ul>
				<div className="navbar-collapse collapse" id="navbar-second-toggle">
					<ul className="nav navbar-nav">
						<li className={location.pathname === "/" ? "active" : ""}>
							<Link to="/">
								<i className="icon-display4 position-left"></i> Tổng quan
							</Link>
						</li>
						<li className={location.pathname === "/purchase" ? "active" : ""}>
							<Link to="/purchase">
								<i className="icon-bag position-left"></i> Mua
							</Link>
						</li>
						<li className={location.pathname === "/sell" ? "active" : ""}>
							<Link to="/sell">
								<i className="icon-cash position-left"></i> Bán
							</Link>
						</li>
						<li className={location.pathname === "/thuchi" ? "active" : ""}>
							<Link to="/thuchi">
								<i className="icon-notebook position-left"></i> Thu chi
							</Link>
						</li>
					</ul>
					<ul className="nav navbar-nav navbar-right">
						<li>
							<p className="navbar-text"></p>
						</li>
						<li className="dropdown dropdown-user">
							<a className="dropdown-toggle" data-toggle="dropdown">
								<img
									src="../../../../global_assets/images/placeholders/placeholder.jpg"
									alt=""
								/>
								<span>admin</span>
								<i className="caret"></i>
							</a>
							<ul className="dropdown-menu dropdown-menu-right">
								<li>
									<Link to="/profile">
										<i className="icon-user"></i> Trang cá nhân
									</Link>
								</li>
								{user?.name === "admin" && (
									<li>
										<Link to="/roles">
											<i className="icon-users4"></i> Quản lý quyền
										</Link>
									</li>
								)}
								{user?.name === "admin" && (
									<li>
										<Link to="/users">
											<i className="icon-list3"></i> Quản lý nhân viên
										</Link>
									</li>
								)}
								{user?.name === "admin" && (
									<li>
										<Link to="/banks">
											<i className="icon-piggy-bank"></i> Quản lý ngân hàng
										</Link>
									</li>
								)}
								{user?.name === "admin" && (
									<li>
										<Link to="/games">
											<i className="icon-puzzle"></i> Quản lý game
										</Link>
									</li>
								)}
								{user?.name === "admin" && (
									<li>
										<Link to="/config">
											<i className="icon-gear"></i> Cài đặt
										</Link>
									</li>
								)}

								<li>
									<Link to="/#">
										<i className="icon-key"></i> Bản quyền
									</Link>
								</li>
								<li>
									<Link to="/#">
										<i className="icon-blocked"></i> Ds Bank hack
									</Link>
								</li>
								<li className="divider"></li>
								<li>
									<a
										onClick={() => {
											logout();
											navigate("/login");
										}}
									>
										<i className="icon-switch2"></i> Logout
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<div
				className="page-container"
				style={{
					backgroundColor:
						location.pathname === "/purchase"
							? "rgb(54, 255, 240)"
							: location.pathname === "/sell"
							? "rgb(255, 166, 241)"
							: location.pathname === "/thuchi"
							? "rgb(96, 255, 89)"
							: "inherit",
				}}
			>
				{children}
			</div>
		</AuthenLayout>
	);
};

export default MainLayout;
