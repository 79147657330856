// export const BASE_URL = "localhost:5000/";
export const BASE_URL = process.env.REACT_APP_BACKEND_URL;
export const logIn = "auth/login";
export const register = "auth/register";
export const logOut = "auth/logout";
export const forgotPassword = "auth/forgot-password";
export const resetPassword = "auth/reset-password";
export const users = "users";
export const changePassword = "change-password";
export const configPasswordAccount = "set-password";
export const roles = "permission";
export const brand = "brand";
export const discount = "discount";
export const orders = "order/admin";

export const products = "product";
export const category = "category";
export const post = "post";
export const overview = "overview";
export const banks = "banks";
export const games = "games";
export const transactions = "transactions";
export const transferMoneys = "transferMoneys";
export const auth = "auth";
export const config = "config";
export default {
	BASE_URL,
	users,
	roles,
	banks,
	games,
	transactions,
	transferMoneys,
	logIn,
	auth,
	config,
};
