import React, { useContext, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import { AuthenContext } from "../context/AuthenContext";
import Dialog from "../layouts/Dialog";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";
import { AlertStatus } from "../common/enum";
import { AlertContext } from "../context/AlertContext";

const ProfilePage = () => {
	const { user } = useContext(AuthenContext);
	const [isOpenFormChangePassowrd, setIsOpenFormChangePassowrd] =
		useState(false);
	return (
		<MainLayout>
			<div className="content-wrapper" style={{ padding: "auto" }}>
				<div
					className="panel panel-flat"
					style={{ width: "50%", margin: "auto" }}
				>
					<div className="panel-heading">
						<h4 className="panel-title">Thông tin cá nhân</h4>
					</div>
					<div className="panel-body">
						<div className="row">
							<label className="col-md-2 text-bold">Tên:</label>
							<span className="col-md-10 ng-binding">{user?.name}</span>
						</div>
						<div className="row">
							<label className="col-md-2 text-bold">Tên đăng nhập:</label>
							<span className="col-md-10 ng-binding">{user?.accountName}</span>
						</div>
						<div className="row">
							<label className="col-md-2 text-bold">Chức vụ:</label>
							<span className="col-md-10 ng-binding">
								{user?.permissions?.name}
							</span>
						</div>
						<div className="row">
							<button
								className="btn btn-primary"
								onClick={() => {
									setIsOpenFormChangePassowrd(true);
								}}
							>
								<i className="icon-lock"></i> Đổi mật khẩu
							</button>
						</div>
					</div>
				</div>
			</div>
			{isOpenFormChangePassowrd && (
				<FormChangePassword
					title={`Tạo mật khẩu mới cho ${user.name}(${user.accountName})`}
					handleClose={() => {
						setIsOpenFormChangePassowrd(false);
					}}
				/>
			)}
		</MainLayout>
	);
};

const FormChangePassword = ({ handleClose, title }) => {
	const { addAlert } = useContext(AlertContext);
	const [formData, setFormData] = useState({});
	const token = JSON.parse(localStorage.getItem("token"));
	const handleSubmit = () => {
		if (formData.password !== formData.confirmPassword)
			alert("Mật khẩu phải trùng khớp");
		axiosClient
			.post(ServiceURL.auth + "/reset-password?token=" + token.token, {
				password: formData.password,
			})
			.then((res) => {
				addAlert("Thành công!", "Đổi mật khẩu thành công");
				setFormData({});
				handleClose();
			})
			.catch((err) =>
				addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error)
			);
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	return (
		<Dialog
			handleClose={handleClose}
			title={title}
			handleSubmitForm={handleSubmit}
		>
			<div className="form-group">
				<div className="row">
					<div className="col-sm-6">
						<label>Mật khẩu</label>
						<input
							type="password"
							placeholder="Mật khẩu"
							class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
							required
							name="password"
							value={formData.password}
							onChange={handleChange}
						/>
					</div>

					<div className="col-sm-6">
						<label>Xác nhận mật khẩu</label>
						<input
							type="password"
							placeholder="Xác nhận mật khẩu"
							class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
							required
							name="confirmPassword"
							value={formData.confirmPassword}
							onChange={handleChange}
						/>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default ProfilePage;
