import React from "react";
import { TransactionStatus } from "../common/enum";

const Widget = ({
	handleDelete,
	handleEdit,
	viewDetail,
	handleChangePassword,
	rowData,
	updateStatusTransaction,
}) => {
	return (
		<ul className="icons-list">
			<li className="dropdown">
				<a
					className="dropdown-toggle"
					data-toggle="dropdown"
					aria-expanded="true"
				>
					<i className="icon-menu9"></i>
				</a>
				<ul className="dropdown-menu dropdown-menu-right">
					{typeof updateStatusTransaction == "function" && (
						<li>
							<a
								onClick={() => {
									updateStatusTransaction(rowData);
								}}
							>
								<i
									className={`icon-${
										rowData.state === TransactionStatus.Paid
											? "blocked"
											: "checkmark3 "
									}`}
								></i>
								{rowData.state === TransactionStatus.Paid
									? "Chưa thanh toán"
									: "Đã thanh toán"}
							</a>
						</li>
					)}
					{typeof viewDetail == "function" && (
						<li>
							<a
								onClick={() => {
									viewDetail(rowData);
								}}
							>
								<i className="icon-clipboard3"></i> Chi tiết
							</a>
						</li>
					)}
					{typeof handleEdit == "function" && (
						<li>
							<a
								onClick={() => {
									handleEdit(rowData);
								}}
							>
								<i className="icon-pencil"></i>Sửa
							</a>
						</li>
					)}

					{typeof handleChangePassword == "function" && (
						<li>
							<a
								onClick={() => {
									handleChangePassword(rowData);
								}}
							>
								<i className="icon-lock"></i> Đổi mật khẩu
							</a>
						</li>
					)}

					{typeof handleDelete == "function" && (
						<li>
							<a
								onClick={() => {
									handleDelete(rowData);
								}}
							>
								<i className="icon-trash"></i>Xóa
							</a>
						</li>
					)}
				</ul>
			</li>
		</ul>
	);
};

export default Widget;
