import React, { useContext } from "react";
import { formatPriceNumber } from "../common/commonFunction";
import { AlertStatus, TransactionStatus } from "../common/enum";
import { AlertContext } from "../context/AlertContext";
import { useState } from "react";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
const TableData = ({ data, columns, tableName, options }) => {
	const { addAlert } = useContext(AlertContext);
	const showDetail = () => {
		console.log("Show Detail");
	};
	const [formFilter, setFormFilter] = useState({
		isDeleted: false,
		fromDate: moment().format("YYYY-MM-DD"),
		toDate: moment().format("YYYY-MM-DD"),
	});
	const copiedToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		addAlert("Thành công!", "Đã copy", AlertStatus.Info);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		options.handleReload(formFilter);
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormFilter({ ...formFilter, [name]: value });
	};

	return (
		<div className="panel panel-flat">
			<div className="panel-heading">
				<h5 className="panel-title text-bold ng-binding">{tableName}</h5>
				{options.isLoad && (
					<div className="heading-elements">
						<ul className="icons-list">
							<li>
								<a
									data-action="reload"
									onClick={() => {
										options.handleReload(formFilter);
									}}
								></a>
							</li>
						</ul>
					</div>
				)}
				{options.isThuChi && (
					<div className="heading-elements">
						<div className="btn-group">
							<button
								className="btn btn-primary"
								onClick={options.addTransaction}
							>
								<i className="icon-plus2"></i> Thêm giao dịch
							</button>
							<button
								className="btn btn-success"
								onClick={options.transferMoney}
							>
								<i className="icon-transmission"></i> Chuyển tiền
							</button>
						</div>
					</div>
				)}
			</div>
			<div className="panel-body">
				{options.isShowFilter && (
					<form
						onSubmit={handleSubmit}
						className="ng-pristine ng-valid ng-valid-date ng-valid-required ng-valid-date-disabled"
					>
						<div className="row">
							<div className="col-md-6">
								<div className="col-xs-6">
									<div className="form-group">
										<label>Từ</label>
										<div className="input-group">
											<input
												aria-label="Date"
												type="date"
												className="form-control ng-pristine ng-untouched ng-valid ng-isolate-scope ng-not-empty ng-valid-date ng-valid-required"
												required
												name="fromDate"
												value={formFilter.fromDate}
												defaultValue={new Date()}
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
								<div className="col-xs-6">
									<div className="form-group">
										<label>Đến</label>
										<div className="input-group">
											<input
												aria-label="Date"
												type="date"
												className="form-control ng-pristine ng-valid ng-isolate-scope ng-not-empty ng-valid-date ng-valid-required ng-touched"
												uib-datepicker-popup="dd/MM/yyyy"
												is-open="datePickerEndOpened"
												datepicker-options="dateOptions"
												required
												name="toDate"
												value={formFilter.toDate}
												onChange={handleChange}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="col-xs-5">
									{options.isFilterUser && options.arrUser && (
										<div className="form-group">
											<label>Nhân viên</label>
											<div>
												<select
													className="form-control ng-pristine ng-untouched ng-valid ng-empty"
													name="createdBy"
													value={formFilter.createdBy}
													onChange={handleChange}
												>
													<option value="" selected="selected">
														Tất cả
													</option>
													{options.isFilterUser &&
														options.arrUser &&
														options.arrUser.map((item) => {
															return (
																<option value={item.id} key={item.id}>
																	{item.name}
																</option>
															);
														})}
												</select>
											</div>
										</div>
									)}
								</div>
								<div className="col-xs-5">
									{options.isFilterDelete && (
										<div className="form-group">
											<label>Hiện theo trạng thái</label>
											<div>
												<select
													className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
													name="state"
													value={formFilter.state}
													onChange={handleChange}
												>
													<option value={-1}>Tất cả</option>
													<option
														value={TransactionStatus.NotPaid}
														selected={
															formFilter.state === TransactionStatus.NotPaid
														}
													>
														Chưa thanh toán
													</option>
													<option
														value={TransactionStatus.Paid}
														selected={
															formFilter.state === TransactionStatus.Paid
														}
													>
														Đã thanh toán
													</option>
												</select>
											</div>
										</div>
									)}
								</div>
								<div className="col-xs-2" style={{ marginTop: 27 }}>
									<button type="submit" className="btn btn-primary">
										<i className="icon-search4"></i>Tìm
									</button>
								</div>
							</div>
						</div>
					</form>
				)}
			</div>
			<div className={`${options.isResponsive ? "table-responsive" : ""}`}>
				<table
					className={`table ${
						options.mormal ? "text-nowrap table-hover table-striped" : ""
					}
					${options.scrollY && "datatable-scroll-y"}`}
				>
					<thead>
						<tr>
							{options.isIdx && <th>STT</th>}
							{columns.map((item, idx) => {
								return <th key={idx}>{item.columnName}</th>;
							})}
						</tr>
					</thead>
					<tbody>
						{data.map((record, recordIdx) => {
							return (
								<tr
									key={recordIdx}
									style={{
										background: record.isDeleted ? "#d59090" : "inherit",
									}}
								>
									{options.isIdx && (
										<td onClick={showDetail}>{recordIdx + 1}</td>
									)}
									{columns.map((column, valueIdx) => {
										let valueRender = record;
										let arrFields = column.columnValue.split(".");
										arrFields.forEach((element) => {
											valueRender = valueRender ? valueRender[element] : "";
										});
										return (
											<td
												title={valueRender}
												key={valueIdx}
												className={
													column.status !== "Interest"
														? ""
														: valueRender > 0
														? "text-success"
														: "text-warning"
												}
											>
												{column.widget ? (
													<column.widget
														rowData={record}
														handleDelete={options.handleDelete}
														handleEdit={options.handleEdit}
														handleChangePassword={options.handleChangePassword}
														viewDetail={options.viewDetail}
														updateStatusTransaction={
															options.updateStatusTransaction
														}
													></column.widget>
												) : column.status === "Status" ? (
													<span
														class={`label label-${
															valueRender === TransactionStatus.Paid
																? "success"
																: "danger"
														}`}
													>
														{valueRender === TransactionStatus.Paid
															? "Đã thanh toán"
															: "Chưa thanh toán"}
													</span>
												) : column.isCopy ? (
													<a
														style={
															column.isMaxLength
																? {
																		maxWidth: "50px",
																		overflow: "hidden",
																		display: "block",
																  }
																: {}
														}
														ngclipboard=""
														data-clipboard-text={valueRender}
														ngclipboard-success="copiedToClipboard()"
														onClick={() => {
															copiedToClipboard(valueRender);
														}}
														className="ng-binding ng-isolate-scope"
													>
														{Number.isInteger(valueRender)
															? formatPriceNumber(valueRender)
															: valueRender}
													</a>
												) : column.type === "label" &&
												  Array.isArray(valueRender) ? (
													valueRender.map((x, i) => {
														return (
															<span
																key={i}
																className="label label-primary ng-binding ng-scope"
															>
																{x}
															</span>
														);
													})
												) : column.type === "thuchi" ? (
													<span
														style={{
															color: record.type == 0 ? "green" : "red",
														}}
													>
														{(record.type == 0 ? "" : "-") +
															formatPriceNumber(valueRender)}
													</span>
												) : column.type === "date" || column.type === "Date" ? (
													new Date(valueRender).getHours() +
													":" +
													new Date(valueRender).getMinutes() +
													"\n" +
													new Date(valueRender).toLocaleDateString("en-GB")
												) : Number.isInteger(valueRender) ? (
													formatPriceNumber(valueRender)
												) : (
													valueRender
												)}
												{column.type === "percen" && "%"}
											</td>
										);
									})}
								</tr>
							);
						})}
						{options?.isTotal && (
							<tr>
								{columns.map((column, valueIdx) => {
									const total = data.reduce(
										(pre, curr) => pre + curr[column.columnValue],
										0
									);
									return column.total ? (
										<td
											className={`text-bold + ${
												column.status !== "Interest"
													? ""
													: total > 0
													? "text-success"
													: "text-warning"
											}`}
											key={valueIdx}
										>
											{formatPriceNumber(total)}
										</td>
									) : (
										<td></td>
									);
								})}
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TableData;
