import React, { useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import StatisticsOverview from "../components/StatisticsOverview";
import TableData from "../components/TableData";
import { columnsProfit } from "../common/configColumns";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";
import queryString from "query-string";
import { TransactionStatus, TransactionType } from "../common/enum";
import moment from "moment";

const HomePage = () => {
	const [arrGame, setArrGame] = useState([]);
	const [arrBank, setArrBank] = useState([]);
	const [arrUser, setArrUser] = useState([]);
	const [tableData, setTableData] = useState([]);
	useEffect(() => {
		handleGetAllGame();
		handleGetAllBank();
		handleGetUser();
		// handleGetData();
	}, []);

	useEffect(() => {
		handleGetData();
	}, [arrGame]);

	const [filterData, setFilterData] = useState({
		fromDate: moment().format("YYYY-MM-DD"),
		toDate: moment().format("YYYY-MM-DD"),
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFilterData({ ...filterData, [name]: value });
	};
	const handleGetAllBank = async () => {
		var res = await axiosClient.get(ServiceURL.banks);
		if (res.data && res.data.results) {
			setArrBank(res.data.results);
		}
	};
	const handleGetAllGame = async () => {
		var res = await axiosClient.get(ServiceURL.games);
		if (res.data && res.data.results) {
			setArrGame(res.data.results);
		}
	};
	const handleGetUser = async () => {
		const res = await axiosClient.get(ServiceURL.users);
		if (res.data && res.data.results) {
			setArrUser(res.data.results);
		}
	};

	const handleGroupData = (listObjects) => {
		// Tạo một đối tượng để lưu trữ các object mới
		let resultObjects = {};

		arrGame.forEach((obj) => {
			resultObjects[obj.id + obj.name] = {
				[obj.id + obj.name]: obj.id,
				name: obj.name,
				arrTransaction: [],
			};
		});

		// Lặp qua mỗi object trong list ban đầu
		listObjects.forEach((obj) => {
			// Kiểm tra xem khóa đã tồn tại trong đối tượng kết quả chưa
			// if (!resultObjects[obj.gameId.id + obj.gameId.name]) {
			// 	// Nếu chưa tồn tại, tạo mới một object có trường gameId.id và b tương ứng
			// 	resultObjects[obj.gameId.id + obj.gameId.name] = {
			// 		[obj.gameId.id + obj.gameId.name]: obj.gameId.id,
			// 		name: obj.gameId.name,
			// 		arrTransaction: [],
			// 	};
			// }

			// Thêm giá trị của 'c' vào danh sách trong object kết quả
			resultObjects[obj.gameId.id + obj.gameId.name]?.arrTransaction.push(obj);
		});

		// Chuyển đổi đối tượng kết quả thành mảng các object
		let resultList = Object.values(resultObjects);
		return resultList;
	};

	const handleGetData = async (filter = filterData) => {
		var res = await axiosClient.get(
			ServiceURL.transactions +
				"?" +
				queryString.stringify({
					...filter,
				})
		);
		if (res.data && res.data.results) {
			let arrData = handleGroupData(res.data.results);
			arrData = arrData.map((item) => {
				//Thông tin mua
				let totalCoinTransferPurchased = item.arrTransaction.reduce(
					(pre, curr) =>
						pre +
						(!curr.isTransactionWithAgenc &&
						curr.type === TransactionType.Purchase &&
						curr.state === TransactionStatus.Paid
							? curr.coinQuantity
							: 0),
					0
				);
				let totalPurchased = item.arrTransaction.reduce(
					(pre, curr) =>
						pre +
						(!curr.isTransactionWithAgenc &&
						curr.type === TransactionType.Purchase &&
						curr.state === TransactionStatus.Paid
							? curr.transferAmount
							: 0),
					0
				);
				let totalInterestPurchased =
					totalCoinTransferPurchased * 0.83 - totalPurchased;
				//Thông tin bán
				let totalCoinTransferSell = item.arrTransaction.reduce(
					(pre, curr) =>
						pre +
						(!curr.isTransactionWithAgenc &&
						curr.type === TransactionType.Sell &&
						curr.state === TransactionStatus.Paid
							? curr.coinQuantity
							: 0),
					0
				);
				let totalSell = item.arrTransaction.reduce(
					(pre, curr) =>
						pre +
						(!curr.isTransactionWithAgenc &&
						curr.type === TransactionType.Sell &&
						curr.state === TransactionStatus.Paid
							? curr.transferAmount
							: 0),
					0
				);
				let totalInterestSell = totalSell - totalCoinTransferSell * 0.83;

				return {
					name: item.name,
					totalPurchase: Math.round(totalPurchased),
					totalInterestPurchase: Math.round(totalInterestPurchased),
					totalSell: Math.round(totalSell),
					totalInterestSell: Math.round(totalInterestSell),
					totalInterest: Math.round(totalInterestSell + totalInterestPurchased),
				};
			});
			setTableData(arrData);
		}
	};
	return (
		<MainLayout>
			<StatisticsOverview
				name={"Banks"}
				arrData={arrBank}
				isBoldHeader={true}
				fieldData={"money"}
				fieldHeader={"name"}
			></StatisticsOverview>
			<StatisticsOverview
				name={"Games"}
				arrData={arrGame}
				isBoldHeader={false}
				fieldData={"coin_value"}
				fieldHeader={"coin_name"}
			></StatisticsOverview>
			<div class="row">
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleGetData(filterData);
					}}
					class="text-right ng-pristine ng-valid ng-valid-date ng-valid-required"
				>
					<div class="col-md-6 col-md-offset-2">
						<div class="col-xs-6">
							<div class="form-group">
								<label style={{ marginRight: 40 }}>Từ</label>
								<div class="input-group">
									<input
										aria-label="Date"
										type="date"
										class="form-control ng-pristine ng-untouched ng-valid ng-isolate-scope ng-not-empty ng-valid-date ng-valid-required"
										uib-datepicker-popup="dd/MM/yyyy"
										ng-model="st"
										is-open="datePickerStartOpened"
										datepicker-options="dateOptions"
										ng-required="true"
										clear-text="Xóa"
										current-text="Hôm nay"
										close-text="Đóng"
										required="required"
										value={filterData.fromDate}
										name="fromDate"
										onChange={handleChange}
									/>
									<span class="input-group-btn">
										<button
											type="button"
											class="btn btn-default"
											style={{ visibility: "hidden" }}
										>
											<i class="icon-calendar"></i>
										</button>
									</span>
								</div>
							</div>
						</div>
						<div class="col-xs-6">
							<div class="form-group">
								<label style={{ marginRight: 40 }}>Đến</label>
								<div class="input-group">
									<input
										aria-label="Date"
										type="date"
										class="form-control ng-pristine ng-untouched ng-valid ng-isolate-scope ng-not-empty ng-valid-date ng-valid-required"
										uib-datepicker-popup="dd/MM/yyyy"
										ng-model="et"
										is-open="datePickerEndOpened"
										datepicker-options="dateOptions"
										ng-required="true"
										clear-text="Xóa"
										current-text="Hôm nay"
										close-text="Đóng"
										required="required"
										value={filterData.toDate}
										name="toDate"
										onChange={handleChange}
									/>

									<span class="input-group-btn">
										<button
											type="button"
											class="btn btn-default"
											style={{ visibility: "hidden" }}
										>
											<i class="icon-calendar"></i>
										</button>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4">
						<div class="col-xs-8">
							<div class="form-group">
								<label>Nhân viên</label>
								<div>
									<select
										class="form-control ng-pristine ng-untouched ng-valid ng-empty"
										value={filterData.createdBy}
										name="createdBy"
										onChange={handleChange}
									>
										<option value={null} selected="selected">
											Tất cả
										</option>
										{arrUser &&
											arrUser.length > 0 &&
											arrUser.map((item) => {
												return (
													<option value={item.id} class="ng-binding ng-scope">
														{item.name} ( {item.accountName})
													</option>
												);
											})}
									</select>
								</div>
							</div>
						</div>
						<div class="col-xs-4" style={{ marginTop: 27 }}>
							<button type="submit" class="btn btn-primary">
								<i class="icon-search4"></i>Tìm
							</button>
						</div>
					</div>
				</form>
			</div>
			<TableData
				data={tableData}
				columns={columnsProfit}
				tableName={"Lợi nhuận"}
				options={{ isTotal: true }}
			></TableData>
		</MainLayout>
	);
};

export default HomePage;
