import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { TransactionType } from "../common/enum";

const NavbarGame = ({ arrGame, textHome, type }) => {
	const [searchParams] = useSearchParams();
	return (
		<div className="tabbable tab-content-bordered" style={{ marginBottom: 20 }}>
			<ul className="nav nav-tabs nav-tabs-justified nav-tabs-component nav-tabs-highlight">
				<li className={`${searchParams.get("gameId") ? "" : "active"}`}>
					<Link
						to={`/${type === TransactionType.Purchase ? "purchase" : "sell"}`}
					>
						<h5>{textHome}</h5>
					</Link>
				</li>
				{arrGame &&
					arrGame.length > 0 &&
					arrGame.map((item, idx) => {
						return (
							<li
								className={`${
									searchParams.get("gameId") !== item.id ? "" : "active"
								}`}
								key={idx}
							>
								<Link
									to={`/${
										type === TransactionType.Purchase ? "purchase" : "sell"
									}?gameId=${item.id}`}
								>
									<h5>{item.name}</h5>
								</Link>
							</li>
						);
					})}
			</ul>
		</div>
	);
};

export default NavbarGame;
