import React, { useContext, useEffect, useRef, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import StatisticsOverview from "../components/StatisticsOverview";
import TableData from "../components/TableData";
import {
	columnsPurchases,
	columnsPurchasesGame,
} from "../common/configColumns";
import NavbarGame from "../components/NavbarGame";
import { useSearchParams } from "react-router-dom";
import GameInfo from "../components/GameInfo";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";
import AddTransaction from "../components/AddTransaction";
import ModalDialog from "../components/ModalDialog";
import {
	AlertStatus,
	ConfigRateType,
	TransactionStatus,
	TransactionType,
} from "../common/enum";
import queryString from "query-string";
import { AlertContext } from "../context/AlertContext";
import Dialog from "../layouts/Dialog";
import { formatPriceNumber } from "../common/commonFunction";
import moment from "moment";
const PurchasePage = () => {
	const [filterTable, setFilterTable] = useState({});
	const { addAlert } = useContext(AlertContext);
	const [tableData, setTableData] = useState([]);
	const [arrGame, setArrGame] = useState([]);
	const [arrBank, setArrBank] = useState([]);
	const [gamePurchaseInfo, setGamePurchaseInfo] = useState({});

	const [arrUser, setArrUser] = useState([]);
	const [isOpenEdit, setIsOpenEdit] = useState(false);
	const [isOpenDetail, setIsOpenDetail] = useState(false);
	const [currentData, setCurrentData] = useState({});
	const [searchParams] = useSearchParams();
	const handleGetUser = async () => {
		const res = await axiosClient.get(ServiceURL.users);
		if (res.data && res.data.results) {
			setArrUser(res.data.results);
		}
	};
	useEffect(() => {
		handleGetAllGame();
		handleGetAllBank();
		handleGetData({
			fromDate: moment().format("YYYY-MM-DD"),
			toDate: moment().format("YYYY-MM-DD"),
		});
		handleGetUser();
		// if (searchParams.get("gameId")) {
		// 	hanleGetGameInfo();
		// }
	}, [searchParams.get("gameId")]);

	const handleGetAllGame = async () => {
		var res = await axiosClient.get(ServiceURL.games);
		if (res.data && res.data.results) {
			setArrGame(res.data.results);
		}
	};

	const handleGetAllBank = async () => {
		var res = await axiosClient.get(ServiceURL.banks);
		if (res.data && res.data.results) {
			setArrBank(res.data.results);
		}
	};

	const hanleGetGameInfo = async (
		filter = {
			fromDate: moment().format("YYYY-MM-DD"),
			toDate: moment().format("YYYY-MM-DD"),
		}
	) => {
		if (!searchParams.get("gameId")) return;
		let paramGameId = searchParams.get("gameId")
			? "?" +
			  queryString.stringify({
					gameId: searchParams.get("gameId"),
					type: TransactionType.Purchase,
					...filter,
			  })
			: "?" +
			  queryString.stringify({
					type: TransactionType.Purchase,
					...filter,
			  });
		var res = await axiosClient.get(ServiceURL.transactions + paramGameId);
		if (res.data && res.data.results) {
			res.data.results = res.data.results.filter((item) => !item.isDeleted);
			let totalCoinTransfer = res.data.results.reduce(
				(pre, curr) =>
					pre + (curr.isTransactionWithAgenc ? 0 : curr.coinQuantity),
				0
			);
			let totalAmountTransfer = res.data.results
				.filter((item) => item.state === TransactionStatus.Paid)
				.reduce(
					(pre, curr) =>
						pre + (curr.isTransactionWithAgenc ? 0 : curr.transferAmount),
					0
				);
			let totalCoinTransferC1 = res.data.results.reduce(
				(pre, curr) =>
					pre + (curr.isTransactionWithAgenc ? curr.coinQuantity : 0),
				0
			);
			let totalAmountTransferC1 = res.data.results.reduce(
				(pre, curr) =>
					pre + (curr.isTransactionWithAgenc ? curr.transferAmount : 0),
				0
			);

			let totalProfit = res.data.results
				.filter((item) => item.state === TransactionStatus.Paid)
				.reduce((pre, curr) => {
					let coinQuantity = curr.isTransactionWithAgenc
						? 0
						: curr.coinQuantity;
					let transferAmount = curr.isTransactionWithAgenc
						? 0
						: curr.transferAmount;

					return pre + Math.round(coinQuantity * 0.83 - transferAmount);
				}, 0);
			setGamePurchaseInfo({
				gameName: res.data.results[0]?.gameId?.coin_name,
				totalCoin: res.data.results[0]?.gameId?.coin_value || 0,
				totalCoinTransfer: totalCoinTransfer,
				totalAmountTransfer: totalAmountTransfer,
				totalCoinTransferC1: totalCoinTransferC1,
				totalAmountTransferC1: totalAmountTransferC1,
				profit: formatPriceNumber(totalProfit),
			});
		}
	};
	const handleGetData = async (filter = filterTable) => {
		setFilterTable(filter);
		if (Object.keys(filter).length > 0) {
			hanleGetGameInfo(filter);
		}
		let paramGameId = searchParams.get("gameId")
			? "?" +
			  queryString.stringify({
					...filter,
					gameId: searchParams.get("gameId"),
					type: TransactionType.Purchase,
			  })
			: "?" +
			  queryString.stringify({
					...filter,
					type: TransactionType.Purchase,
			  });
		var res = await axiosClient.get(ServiceURL.transactions + paramGameId);
		if (res.data && res.data.results) {
			setTableData(res.data.results);
		}
	};

	const updateStatusTransaction = (rowData) => {
		axiosClient
			.patch(ServiceURL.transactions + "/updateStatus/" + rowData._id, {
				type: TransactionType.Purchase,
				status:
					rowData.state === TransactionStatus.Paid
						? TransactionStatus.NotPaid
						: TransactionStatus.Paid,
			})
			.then((res) => {
				addAlert("Thành công!", "Cập nhật trạng thái thành công");
				handleGetAllBank();
				rowData.state =
					rowData.state === TransactionStatus.Paid
						? TransactionStatus.NotPaid
						: TransactionStatus.Paid;
				hanleGetGameInfo(filterTable);
			})
			.catch((err) => {
				addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error);
			});
	};
	const handleEdit = (rowData) => {
		setCurrentData(rowData);
		setIsOpenDetail(false);
		setIsOpenEdit(true);
	};
	const handleDelete = async (record) => {
		let isConfirm = window.confirm("Bạn có chắc chắn muốn xóa không ?");
		if (isConfirm)
			await axiosClient
				.delete(ServiceURL.transactions + "/" + record._id)
				.then((res) => {
					addAlert("Thành công!", "Xóa giao dịch thành công");
					handleGetData();
					handleGetAllBank();
				})
				.catch((err) =>
					addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error)
				);
	};
	const viewDetail = (rowData) => {
		setCurrentData(rowData);
		setIsOpenDetail(true);
	};

	return (
		<MainLayout>
			<NavbarGame
				arrGame={arrGame}
				textHome={"Mua Tổng"}
				type={TransactionType.Purchase}
			></NavbarGame>
			{searchParams.get("gameId") && (
				<GameInfo
					data={gamePurchaseInfo}
					isPurchased={true}
					gameName={gamePurchaseInfo.gameName}
					gameInfo={arrGame.find((x) => x.id == searchParams.get("gameId"))}
				/>
			)}
			<StatisticsOverview
				name={"Banks"}
				arrData={arrBank}
				isBoldHeader={true}
				fieldData={"money"}
				fieldHeader={"name"}
			></StatisticsOverview>
			{searchParams.get("gameId") && (
				<AddTransaction
					arrBank={arrBank}
					isPurchase={true}
					gameId={searchParams.get("gameId")}
					handleGetData={handleGetData}
					handleGetBank={handleGetAllBank}
					handleGetGame={handleGetAllGame}
				/>
			)}
			<TableData
				columns={
					searchParams.get("gameId") ? columnsPurchasesGame : columnsPurchases
				}
				data={tableData}
				tableName={"Lịch sử giao dịch"}
				options={{
					isIdx: true,
					isShowFilter: true,
					isLoad: true,
					isFilterUser: true,
					isFilterDelete: true,
					arrUser: arrUser,
					handleReload: handleGetData,
					handleDelete: handleDelete,
					updateStatusTransaction: updateStatusTransaction,
					viewDetail: viewDetail,
					handleEdit: handleEdit,
				}}
			></TableData>
			{isOpenDetail && (
				<PurchasePageDetail
					handleClose={() => {
						setIsOpenDetail(false);
						setCurrentData({});
					}}
					data={currentData}
				></PurchasePageDetail>
			)}
			{isOpenEdit && (
				<FormEdit
					data={currentData}
					handleClose={() => {
						setIsOpenEdit(false);
						setCurrentData({});
					}}
					handleGetData={handleGetData}
				/>
			)}
		</MainLayout>
	);
};

const PurchasePageDetail = ({
	data,
	handleEdit,
	handleClose,
	handleDelete,
}) => {
	return (
		<ModalDialog
			data={data}
			handleClose={handleClose}
			handleEdit={handleEdit}
			handleDelete={handleDelete}
			title={"Thông tin giao dịch mua"}
		>
			<table className="table dataTable table-hover">
				<tbody>
					<tr>
						<td>Nhân viên</td>
						<td className="ng-binding">{data?.createdBy?.name}</td>
					</tr>
					<tr>
						<td>Thời gian</td>
						<td className="ng-binding">{data?.createdAt}</td>
					</tr>
					<tr>
						<td>Nhân vật</td>
						<td className="ng-binding">{data?.userName}</td>
					</tr>
					<tr>
						<td>Bank</td>
						<td className="ng-binding">{data?.bankId.name}</td>
					</tr>
					<tr>
						<td>Đơn giá</td>
						<td className="ng-binding">{data?.unitPrice}</td>
					</tr>
					<tr>
						<td className="ng-binding">Tiền chuyển</td>
						<td className="text-warning">{data?.transferAmount}</td>
					</tr>
					<tr>
						<td>Tiền trước giao dịch</td>
						<td className="ng-binding">{data?.amountBeforeTransactions}</td>
					</tr>
					<tr>
						<td>Tiền sau giao dịch</td>
						<td className="ng-binding">{data?.amountAfterTransactions}</td>
					</tr>
					<tr>
						<td className="ng-binding">Coin nhận</td>
						<td className="text-success">{data?.coinQuantity}</td>
					</tr>
					<tr>
						<td className="ng-binding">Coin trước giao dịch</td>
						<td className="ng-binding">{data?.coinBeforeTransactions}</td>
					</tr>
					<tr>
						<td className="ng-binding">Coin sau giao dịch</td>
						<td className="ng-binding">{data?.coinAfterTransactions}</td>
					</tr>
					<tr>
						<td>Trạng thái</td>
						<td>
							<span
								class={`label label-${
									data?.state === TransactionStatus.Paid ? "success" : "danger"
								}`}
							>
								{data?.state === TransactionStatus.Paid
									? "Đã thanh toán"
									: "Chưa thanh toán"}
							</span>
						</td>
					</tr>
					<tr>
						<td>Nội dung</td>
						<td className="ng-binding">{data?.description}</td>
					</tr>
				</tbody>
			</table>
		</ModalDialog>
	);
};

const FormEdit = ({ data, handleClose, handleGetData }) => {
	useEffect(() => {
		handleGetBank();
		handleGetConfig();
	}, []);

	const [arrConfig, setArrConfig] = useState([]);
	const [formData, setFormData] = useState({ ...data });
	const [arrBank, setArrBank] = useState([]);
	const handleGetConfig = async () => {
		const query = queryString.stringify({
			type: ConfigRateType.Sell,
		});
		const res = await axiosClient.get(ServiceURL.config + "?" + query);
		if (res.data && res.data.results) {
			setArrConfig(res.data.results);
		}
	};
	const handleGetBank = async () => {
		var res = await axiosClient.get(ServiceURL.banks);
		if (res.data && res.data.results) {
			setArrBank(res.data.results);
		}
	};
	const handleChange = (e) => {
		const { name, value, type } = e.target;
		if (name === "isTransactionWithAgenc" || name === "coinQuantity") {
			const isTransactionWithAgencValue =
				name === "isTransactionWithAgenc"
					? e.target.checked
					: formData.isTransactionWithAgenc;
			const coinQuantityValue =
				name === "coinQuantity" ? value : formData.coinQuantity;

			const valueConfig = arrConfig.find(
				(item) =>
					(item.from < coinQuantityValue || item.from == coinQuantityValue) &&
					(item.to > coinQuantityValue || item.to == coinQuantityValue)
			);
			const unitPrice =
				!isTransactionWithAgencValue && !coinQuantityValue
					? 0
					: isTransactionWithAgencValue
					? 83
					: valueConfig?.value
					? valueConfig?.value
					: 83;
			setFormData({
				...formData,
				[name]: type === "checkbox" ? e.target.checked : value,
				unitPrice: unitPrice,
				transferAmount: Math.round(
					((unitPrice || 0) * (coinQuantityValue || 0)) / 100
				),
			});
		} else {
			setFormData({
				...formData,
				[name]: type === "checkbox" ? e.target.checked : value,
			});
		}
	};
	const { addAlert } = useContext(AlertContext);
	const handleSubmit = (e) => {
		e.preventDefault();
		if (formData.state === TransactionStatus.Paid) {
			addAlert(
				"Thất bại",
				"Bạn không thể sửa giao dịch đã thanh toán",
				TransactionStatus.Paid
			);
			return;
		}
		axiosClient
			.patch(ServiceURL.transactions + "/" + formData._id, {
				...formData,
				gameId: formData.gameId.id,
				bankId: formData.bankId.id,
				createdBy: formData.createdBy.id,
			})
			.then(() => {
				handleClose();
				handleGetData();
				addAlert("Thành công", "Sửa giao dịch thành công");
			})
			.catch((err) => {
				addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error);
			});
	};
	return (
		<Dialog
			handleClose={handleClose}
			title={"Sửa thông tin giao dịch mua"}
			handleSubmitForm={handleSubmit}
		>
			<div className="form-group">
				<div className="row">
					<div className="col-sm-6">
						<label>Username</label>
						<input
							type="text"
							placeholder="Username"
							class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required"
							name="userName"
							value={formData.userName}
							onChange={handleChange}
							required
						/>
					</div>
					<div className="row">
						<div className="col-sm-6">
							<label>Bank</label>
							<select
								className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
								name="bankId"
								value={formData.bankId}
								onChange={handleChange}
							>
								{arrBank &&
									arrBank.length > 0 &&
									arrBank.map((item, idx) => {
										return (
											<option
												key={idx}
												value={item.id}
												className="ng-binding ng-scope"
											>
												{item.name}
											</option>
										);
									})}
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<div className="col-sm-6">
						<label>Số coin</label>
						<input
							type="number"
							className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
							placeholder="Số coin"
							required
							name="coinQuantity"
							value={formData.coinQuantity}
							onChange={handleChange}
						/>
					</div>
					<div className="col-sm-6">
						<label>Đơn giá</label>
						<input
							type="number"
							className="form-control"
							placeholder="Đơn giá"
							disabled
							required
							name="unitPrice"
							value={formData.unitPrice}
							onChange={handleChange}
						/>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<div className="col-sm-6">
						<label>Số tiền cần chuyển</label>
						<input
							type="text"
							className="form-control"
							placeholder="Số tiền cần chuyển"
							disabled
							name="transferAmount"
							required
							value={formData.transferAmount}
							//onChange={handleChange}
						/>
					</div>
					<div className="col-sm-6 text-center">
						<div className="row">
							<label>Giao dịch với đại lý:</label>
						</div>

						<div className="checker row">
							<span
								className={`${
									formData.isTransactionWithAgenc ? "checked" : ""
								}`}
							>
								<input
									type="checkbox"
									className="styled ng-pristine ng-untouched ng-valid ng-empty"
									//ng-model="transaction.isC1"
									name="isTransactionWithAgenc"
									value={formData.isTransactionWithAgenc}
									//checked={formData.isTransactionWithAgenc}
									onChange={handleChange}
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="form-group">
				<label>Nội dung</label>
				<input
					type="text"
					class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required"
					placeholder="Nội dung"
					name="description"
					value={formData.description}
					onChange={handleChange}
					required
				/>
			</div>
		</Dialog>
	);
};

export default PurchasePage;
