import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";
import queryString from "query-string";
import { AlertStatus, ConfigRateType } from "../common/enum";
import { AlertContext } from "../context/AlertContext";

const ConfigPage = () => {
	return (
		<MainLayout>
			<div className="row">
				<h5 className="title text-bold">Cài đặt tỉ lệ</h5>
				<ConfigRate isPurchased={true}></ConfigRate>
				<ConfigRate isPurchased={false}></ConfigRate>
			</div>
		</MainLayout>
	);
};

const ConfigRate = ({ isPurchased = false }) => {
	const { addAlert } = useContext(AlertContext);
	const [formData, setFormData] = useState({});
	const [arrData, setArrData] = useState([]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	useEffect(() => {
		handleGetData();
	}, []);

	const handleGetData = async () => {
		const query = queryString.stringify({
			type: isPurchased ? ConfigRateType.Purchase : ConfigRateType.Sell,
		});
		const res = await axiosClient.get(ServiceURL.config + "?" + query);
		if (res.data && res.data.results) {
			setArrData(res.data.results);
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (formData.to < formData.from) {
			addAlert(
				"Thất bại",
				"Giá trị đến không được nhỏ hơn giá trị từ",
				AlertStatus.Error
			);
			return;
		}
		if (
			arrData.some(
				(item) => item.to > formData.from || item.to == formData.from
			)
		) {
			addAlert(
				"Thất bại",
				"Khoảng giá trị không được trùng lặp",
				AlertStatus.Error
			);
			return;
		}
		axiosClient
			.post(ServiceURL.config, {
				...formData,
				type: isPurchased ? ConfigRateType.Purchase : ConfigRateType.Sell,
			})
			.then((res) => {
				addAlert("Thành công", "Thêm cấu hình thành công");
				setArrData([...arrData, formData]);
				setFormData({
					from: 0,
					to: 0,
					value: 0,
				});
			})
			.catch(() => {
				addAlert("Thất bại", "Có lỗi xảy ra", AlertStatus.Error);
			});
	};
	const handleRemove = (id) => {
		axiosClient
			.delete(ServiceURL.config + "/" + id)
			.then((res) => {
				addAlert("Thành công", "Xóa cấu hình thành công");
				handleGetData();
			})
			.catch(() => {
				addAlert("Thất bại", "Có lỗi xảy ra", AlertStatus.Error);
			});
	};
	return (
		<div className="col-lg-6">
			<div className="panel panel-flat">
				<div className="panel-heading">
					<h6 className="panel-title text-bold text-warning">
						{isPurchased ? "Mua" : "Bán"}
					</h6>
				</div>
				<div className="panel-body">
					<form className="form-horizontal ng-pristine ng-valid">
						{arrData &&
							arrData.length > 0 &&
							arrData.map((item, idx) => {
								return (
									<div
										class="form-group ng-scope"
										ng-repeat="(key, value) in data.rate.purchase"
									>
										<div className="col-xs-3" key={idx}>
											<label className="control-label">Từ</label>
											<input
												type="text"
												class="form-control"
												value={item.from}
												disabled
											/>
										</div>
										<div className="col-xs-3">
											<label className="control-label">Đến</label>
											<input
												type="text"
												class="form-control"
												value={item.to}
												disabled
											/>
										</div>
										<div className="col-xs-3">
											<label className="control-label">Giá trị</label>
											<input
												type="text"
												class="form-control"
												value={item.value}
												disabled
											/>
										</div>
										<div className="col-xs-3" style={{ paddingTop: 28 }}>
											<button
												ng-click="removePurchaseRate(key)"
												class="btn btn-warning btn-sm"
												onClick={(e) => {
													e.preventDefault();
													handleRemove(item.id);
												}}
											>
												<i className="icon-trash"></i>
											</button>
										</div>
									</div>
								);
							})}
						<div className="form-group">
							<div className="col-xs-3">
								<label className="control-label">Từ</label>
								<input
									type="number"
									class="form-control ng-pristine ng-untouched ng-valid ng-empty"
									ng-model="sellNewRow.from"
									name="from"
									value={formData.from}
									onChange={handleChange}
								/>
							</div>
							<div className="col-xs-3">
								<label className="control-label">Đến</label>
								<input
									type="number"
									class="form-control ng-pristine ng-untouched ng-valid ng-empty"
									name="to"
									value={formData.to}
									onChange={handleChange}
								/>
							</div>
							<div className="col-xs-3">
								<label className="control-label">Giá trị</label>
								<input
									type="number"
									class="form-control ng-pristine ng-untouched ng-valid ng-empty"
									name="value"
									value={formData.value}
									onChange={handleChange}
								/>
							</div>
							<div className="col-xs-3" style={{ paddingTop: 28 }}>
								<button
									onClick={handleSubmit}
									className="btn btn-primary btn-sm"
								>
									<i className="icon-plus2"></i>
								</button>
							</div>
						</div>
						{/* <div className="text-right">
							<button ng-click="submit()" className="btn btn-primary">
								Xác nhận
							</button>
						</div> */}
					</form>
				</div>
			</div>
		</div>
	);
};
export default ConfigPage;
