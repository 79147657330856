import React from "react";
import { formatPriceNumber } from "../common/commonFunction";

const StatisticsOverview = ({
	name,
	arrData,
	isBoldHeader,
	fieldHeader,
	fieldData,
}) => {
	return (
		<div
			className="panel panel-flat table-responsive ng-scope"
			ng-controller="bankBar"
		>
			<div className="panel-heading">
				<h5 className="panel-title text-bold">{name}</h5>
				<h6>
					Tổng:{" "}
					<span className="text-bold ng-binding">
						{formatPriceNumber(
							arrData.reduce((pre, curr) => pre + curr[fieldData], 0)
						)}
					</span>
				</h6>
			</div>
			<table className="table table-bordered">
				<tbody>
					<tr>
						{arrData.map((item, idx) => {
							return (
								<td key={idx} class={isBoldHeader ? "text-bold" : ""}>
									{item[fieldHeader]}
								</td>
							);
						})}
					</tr>
					<tr>
						{arrData.map((item, idx) => {
							return <td key={idx}>{formatPriceNumber(item[fieldData])}</td>;
						})}
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default StatisticsOverview;
