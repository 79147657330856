import React, { createContext, useState, useEffect } from "react";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";

const AuthenContext = createContext();

const AuthenProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);
	const [refreshToken, setRefreshToken] = useState(null);

	useEffect(() => {
		const localStorageToken = localStorage.getItem("token");
		if (localStorageToken) {
			const user = JSON.parse(localStorage.getItem("user"));
			setUser(user);
			setToken(JSON.parse(localStorageToken));
			setRefreshToken(JSON.parse(localStorage.getItem("refreshToken")));
		}
	}, []);

	const login = (user, token, refreshToken) => {
		localStorage.setItem("user", JSON.stringify(user));
		localStorage.setItem("token", JSON.stringify(token));
		localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
		setUser(user);
		setToken(token);
		setRefreshToken(refreshToken);
	};

	const logout = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("token");
		localStorage.removeItem("refreshToken");
		setUser(null);
		setToken(null);
		setRefreshToken(null);
	};

	const refreshAccessToken = async () => {
		axiosClient
			.post(ServiceURL.auth + "/refresh-tokens", {
				refreshToken: refreshToken.token,
			})
			.then((data) => {
				const { user, token } = data;
				localStorage.setItem("user", JSON.stringify(user));
				localStorage.setItem("token", token);
				setUser(user);
				setToken(token);
			})
			.catch(() => {
				logout();
			});
	};

	const value = {
		user,
		token,
		login,
		logout,
		refreshAccessToken,
	};

	return (
		<AuthenContext.Provider value={value}>{children}</AuthenContext.Provider>
	);
};

export { AuthenContext, AuthenProvider };
