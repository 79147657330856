import React from "react";

const ModalDialog = ({
	children,
	title,
	handleClose,
	data,
	handleEdit,
	handleDelete,
}) => {
	return (
		<>
			<div
				uib-modal-window="modal-window"
				className="modal fade ng-scope ng-isolate-scope in"
				role="dialog"
				index="0"
				animate="animate"
				tabindex="-1"
				uib-modal-animation-class="fade"
				modal-in-class="in"
				modal-animation="true"
				style={{ zIndex: 2000, display: "block" }}
			>
				<div className="modal-dialog ">
					<div className="modal-content" uib-modal-transclude="">
						<div className="modal-content ng-scope">
							<div className="modal-header">
								<button type="button" className="close" onClick={handleClose}>
									×
								</button>
								<h5 className="modal-title">{title}</h5>
							</div>
							<div className="modal-body">{children}</div>
							<div className="modal-footer">
								{!data?.isDeleted && (
									<button
										type="button"
										class="btn btn-primary ng-scope"
										onClick={handleEdit}
									>
										Sửa
									</button>
								)}
								{!data?.isDeleted && (
									<button
										type="button"
										class="btn btn-warning ng-scope"
										onClick={handleDelete}
									>
										Xóa
									</button>
								)}
								<button
									type="button"
									class="btn btn-link"
									onClick={handleClose}
								>
									Đóng
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				uib-modal-backdrop="modal-backdrop"
				className="modal-backdrop fade ng-scope in"
				uib-modal-animation-class="fade"
				modal-in-class="in"
				modal-animation="true"
				data-bootstrap-modal-aria-hidden-count="1"
				aria-hidden="true"
				style={{ zIndex: 1040 }}
			></div>
		</>
	);
};

export default ModalDialog;
