import React, { useContext, useEffect, useState } from "react";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";
import { AlertContext } from "../context/AlertContext";
import { AlertStatus, ConfigRateType, TransactionStatus } from "../common/enum";
import { formatPriceNumber } from "../common/commonFunction";
import queryString from "query-string";

const AddTransaction = ({
	isPurchase,
	arrBank,
	gameId,
	handleGetData,
	handleGetBank,
	handleGetGame,
}) => {
	const { addAlert } = useContext(AlertContext);
	const [formData, setFormData] = useState({
		bankId: arrBank[0]?.id,
		rateCost: 0,
		isNotPaid: false,
	});
	const [arrConfig, setArrConfig] = useState([]);
	useEffect(() => {
		handleGetConfig();
	}, []);

	const handleGetConfig = async () => {
		const query = queryString.stringify({
			type: isPurchase ? ConfigRateType.Purchase : ConfigRateType.Sell,
		});
		const res = await axiosClient.get(ServiceURL.config + "?" + query);
		if (res.data && res.data.results) {
			setArrConfig(res.data.results);
		}
	};
	const handleChange = (e) => {
		const { name, value, type } = e.target;
		const rateValue = name === "rateCost" ? value : formData.rateCost;
		let rateRealTransactions =
			rateValue == 1 ? 1.010101235 : rateValue == 2 ? 2.040816049 : 0;
		if (
			isPurchase &&
			(name === "isTransactionWithAgenc" ||
				name === "coinQuantity" ||
				name === "rateCost")
		) {
			const isTransactionWithAgencValue =
				name === "isTransactionWithAgenc"
					? e.target.checked
					: formData.isTransactionWithAgenc;
			const coinQuantityValue =
				name === "coinQuantity" ? value : formData.coinQuantity;

			const valueConfig = arrConfig.find(
				(item) =>
					(item.from < coinQuantityValue || item.from == coinQuantityValue) &&
					(item.to > coinQuantityValue || item.to == coinQuantityValue)
			);
			const unitPrice =
				!isTransactionWithAgencValue && !coinQuantityValue
					? 0
					: isTransactionWithAgencValue
					? 83
					: valueConfig?.value
					? valueConfig?.value
					: 83;
			setFormData({
				...formData,
				[name]: type === "checkbox" ? e.target.checked : value,
				unitPrice: unitPrice,
				transferAmount: Math.round(
					Math.round(
						((1 + rateRealTransactions / 100) *
							((unitPrice || 0) * (coinQuantityValue || 0))) /
							100
					)
				),
			});
		} else if (
			!isPurchase &&
			(name === "isTransactionWithAgenc" ||
				name === "transferAmount" ||
				name === "rateCost")
		) {
			const isTransactionWithAgencValue =
				name === "isTransactionWithAgenc"
					? e.target.checked
					: formData.isTransactionWithAgenc;
			const transferAmountValue =
				name === "transferAmount" ? value : formData.transferAmount;

			const valueConfig = arrConfig.find(
				(item) =>
					(item.from < transferAmountValue ||
						item.from == transferAmountValue) &&
					(item.to > transferAmountValue || item.to == transferAmountValue)
			);

			const unitPrice =
				!isTransactionWithAgencValue && !transferAmountValue
					? 0
					: isTransactionWithAgencValue
					? 83
					: valueConfig?.value
					? valueConfig?.value
					: 83;
			setFormData({
				...formData,
				[name]: type === "checkbox" ? e.target.checked : value,
				unitPrice: unitPrice,
				coinQuantity: Math.round(
					transferAmountValue /
						(unitPrice * 0.01 * (1 - rateRealTransactions / 100.0))
				),
			});
		} else {
			setFormData({
				...formData,
				[name]: type === "checkbox" ? e.target.checked : value,
			});
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		axiosClient
			.post(ServiceURL.transactions, {
				...formData,
				gameId: gameId,
				type: isPurchase ? 0 : 1,
				state: formData.isNotPaid
					? TransactionStatus.NotPaid
					: TransactionStatus.Paid,
				bankId: formData.bankId ? formData.bankId : arrBank[0].id,
			})
			.then(() => {
				setFormData({
					bankId: arrBank[0]?.id,
					rateCost: 0,
					userName: "",
					unitPrice: 0,
					coinQuantity: 0,
					isTransactionWithAgenc: false,
					description: "",
					isNotPaid: false,
					transferAmount: 0,
				});
				addAlert("Thành công!", "Cập nhật trạng thái thành công");
				handleGetData();
				handleGetBank();
				handleGetGame();
			})
			.catch((err) => {
				addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error);
			});
	};
	return (
		<div className="panel panel-flat ng-scope" ng-if="gameId !== 'all'">
			<div className="panel-heading">
				<h5 className="panel-title text-bold">Thêm giao dịch</h5>
			</div>
			<div className="panel-body">
				<form
					onSubmit={handleSubmit}
					className="ng-pristine ng-invalid ng-invalid-required"
				>
					<div className="row">
						<div className="col-md-2">
							<div className="form-group">
								<input
									id="txtPurchaseUserName"
									type="text"
									className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
									placeholder="Username"
									name="userName"
									required
									value={formData.userName}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<select
									className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
									name="bankId"
									value={formData.bankId}
									onChange={handleChange}
								>
									{arrBank &&
										arrBank.length > 0 &&
										arrBank.map((item, idx) => {
											return (
												<option
													key={idx}
													value={item.id}
													className="ng-binding ng-scope"
												>
													{item.name}
												</option>
											);
										})}
								</select>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								{isPurchase ? (
									<input
										type="number"
										className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
										placeholder="Số coin"
										name="coinQuantity"
										required
										value={formData.coinQuantity}
										onChange={handleChange}
									/>
								) : (
									<input
										type="number"
										className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
										placeholder="Số tiền nhận"
										name="transferAmount"
										required
										value={formData.transferAmount}
										onChange={handleChange}
									/>
								)}
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<input
									type="number"
									className="form-control"
									placeholder="Đơn giá"
									disabled
									required
									name="unitPrice"
									value={formData.unitPrice}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								{isPurchase ? (
									<input
										type="text"
										className="form-control"
										placeholder="Số tiền cần chuyển"
										disabled
										required
										name="transferAmount"
										value={formatPriceNumber(formData.transferAmount)}
										//onChange={handleChange}
									/>
								) : (
									<input
										type="text"
										className="form-control"
										placeholder="Số coin chuyển"
										disabled
										required
										name="coinQuantity"
										value={formatPriceNumber(formData.coinQuantity)}
										//onChange={handleChange}
									/>
								)}
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<label className="checkbox-inline checkbox-right">
									<div className="checker">
										<span
											className={`${
												formData.isTransactionWithAgenc ? "checked" : ""
											}`}
										>
											<input
												type="checkbox"
												className="styled ng-pristine ng-untouched ng-valid ng-empty"
												//ng-model="transaction.isC1"
												name="isTransactionWithAgenc"
												value={formData.isTransactionWithAgenc}
												//checked={formData.isTransactionWithAgenc}
												onChange={handleChange}
											/>
										</span>
									</div>
									Giao dịch với đại lý:
								</label>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div
								className="form-group has-feedback has-feedback-left"
								id="contentInput"
							>
								<input
									type="text"
									className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
									placeholder="Nội dung"
									name="description"
									value={formData.description}
									onChange={handleChange}
									required
								/>
								<div
									className="form-control-feedback hide"
									id="isLoadingContent"
								>
									<i className="icon-spinner2 spinner"></i>
								</div>
								<span className="help-block hide">
									Tài khoản ngân hàng bị chặn
								</span>
							</div>
						</div>
						<div className="col-md-3">
							<div className="form-group">
								<label
									className="text-semibold label-inline"
									style={{
										paddingRight: 16,
										margin: 0,
										verticalAlign: "middle",
									}}
								>
									Phí GD
								</label>
								<label className="radio-inline radio-right transaction-radio">
									<input
										type="radio"
										ng-model="transaction.fee"
										ng-value="0"
										className="ng-pristine ng-untouched ng-valid ng-not-empty"
										value="0"
										required
										name="rateCost"
										checked={formData.rateCost == 0}
										onChange={handleChange}
									/>
									0%
								</label>
								<label className="radio-inline radio-right transaction-radio">
									<input
										type="radio"
										ng-model="transaction.fee"
										ng-value="1"
										className="ng-pristine ng-untouched ng-valid ng-not-empty"
										value="1"
										required
										name="rateCost"
										checked={formData.rateCost == 1}
										onChange={handleChange}
									/>
									1%
								</label>
								<label className="radio-inline radio-right transaction-radio">
									<input
										type="radio"
										ng-model="transaction.fee"
										ng-value="2"
										className="ng-pristine ng-untouched ng-valid ng-not-empty"
										name="rateCost"
										value="2"
										required
										checked={formData.rateCost == 2}
										onChange={handleChange}
									/>
									2%
								</label>
							</div>
						</div>
						<div className="col-md-2">
							<label className="checkbox-inline checkbox-right">
								<div className="checker">
									<span className={`${formData.isNotPaid ? "checked" : ""}`}>
										<input
											type="checkbox"
											className="styled ng-pristine ng-untouched ng-valid ng-empty"
											//ng-model="transaction.isC1"
											name="isNotPaid"
											value={formData.isNotPaid}
											//checked={formData.isTransactionWithAgenc}
											onChange={handleChange}
										/>
									</span>
								</div>
								Đánh dấu chưa thanh toán
							</label>
						</div>
						<div className="col-md-1 text-right">
							<button
								type="submit"
								className="btn btn-primary"
								id="btnAddTransaction"
							>
								Thêm <i className="icon icon-add"></i>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddTransaction;
