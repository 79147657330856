const EnumInterest = {
	Zero: 0,
	Interest: 1,
	Loss: 2,
};

const TransactionStatus = {
	NotPaid: 0,
	Paid: 1,
};

const AlertStatus = {
	Success: 0,
	Error: 1,
	Info: 2,
};

const TransactionType = {
	Purchase: 0,
	Sell: 1,
};

const ConfigRateType = {
	Purchase: 0,
	Sell: 1,
};

export {
	EnumInterest,
	TransactionStatus,
	AlertStatus,
	TransactionType,
	ConfigRateType,
};
