import React, { createContext, useState } from "react";

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
	const [alerts, setAlerts] = useState([]);

	const addAlert = (title, message, status = 0) => {
		const alert = { title, message, status, visible: true };
		setAlerts([...alerts, alert]);
		setTimeout(() => (alert.visible = false), 2000);
	};

	const removeAlert = (id) => {
		setAlerts(alerts.filter((alert) => alert.id !== id));
	};

	return (
		<AlertContext.Provider value={{ alerts, addAlert, removeAlert }}>
			{children}
		</AlertContext.Provider>
	);
};

export { AlertContext, AlertProvider };
