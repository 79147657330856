import React, { useEffect, useState } from "react";
import { AlertStatus } from "../common/enum";

const Alert = ({ message, status, title }) => {
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		const timeout = setTimeout(() => setVisible(false), 2000);
		return () => clearTimeout(timeout);
	}, []);
	return visible ? (
		<div
			class={`ui-pnotify bg-${
				status === AlertStatus.Error
					? "danger"
					: status === AlertStatus.Info
					? "info"
					: "success"
			} ui-pnotify-fade-normal ui-pnotify-mobile-able ui-pnotify-in ui-pnotify-fade-in ui-pnotify-move`}
			aria-live="assertive"
			aria-role="alertdialog"
			style={{
				display: "none",
				width: 300,
				right: 20,
				top: 20,
				cursor: "pointer",
			}}
		>
			<div
				className="brighttheme ui-pnotify-container brighttheme-notice ui-pnotify-shadow"
				role="alert"
				style={{ minHeight: 16 }}
			>
				<div
					className="ui-pnotify-closer"
					aria-role="button"
					tabindex="0"
					title="Close"
					style={{ cursor: "pointer", visibility: "hidden" }}
				>
					<span className="brighttheme-icon-closer"></span>
				</div>
				<div
					className="ui-pnotify-sticker"
					aria-role="button"
					aria-pressed="false"
					tabindex="0"
					title="Stick"
					style={{ cursor: "pointer", visibility: "hidden" }}
				>
					<span
						className="brighttheme-icon-sticker"
						aria-pressed="false"
					></span>
				</div>
				<div className="ui-pnotify-icon">
					<span className="brighttheme-icon-notice"></span>
				</div>
				<h4 className="ui-pnotify-title">{title}</h4>
				<div className="ui-pnotify-text" aria-role="alert">
					{message}
				</div>
				<div
					className="ui-pnotify-action-bar"
					style={{
						marginTop: 5,
						clear: "both",
						textAlign: "right",
						display: "none",
					}}
				></div>
			</div>
		</div>
	) : (
		<></>
	);
};

export default Alert;
