const roles = [
	"user",
	"bank",
	"game",
	"log",
	"sell",
	"purchase",
	"role",
	"finance_report",
	"license",
];

const roleRights = roles;

export default {
	roles,
	roleRights,
};
