import Widget from "../components/Widget";

const columnsProfit = [
	{ columnName: "Game", columnValue: "name" },
	{ columnName: "Tổng mua", columnValue: "totalPurchase", total: true },
	{
		columnName: "Lãi mua",
		columnValue: "totalInterestPurchase",
		status: "Interest",
		total: true,
	},
	{ columnName: "Tổng bán", columnValue: "totalSell", total: true },
	{
		columnName: "Lãi bán",
		columnValue: "totalInterestSell",
		status: "Interest",
		total: true,
	},
	{
		columnName: "Tổng lãi",
		columnValue: "totalInterest",
		status: "Interest",
		total: true,
	},
];

const columnsPurchases = [
	{
		columnName: "Thời gian",
		columnValue: "createdAt",
		type: "Date",
	},
	{
		columnName: "Nhân viên",
		columnValue: "createdBy.name",
	},
	{
		columnName: "Nhân vật",
		columnValue: "userName",
		isCopy: true,
	},
	{
		columnName: "Game",
		columnValue: "gameId.name",
	},
	{
		columnName: "Bank",
		columnValue: "bankId.name",
	},
	{
		columnName: "Đơn giá",
		columnValue: "unitPrice",
	},
	{
		columnName: "Phí",
		columnValue: "rateCost",
	},
	{
		columnName: "Tiền chuyển",
		columnValue: "transferAmount",
		isCopy: true,
	},
	{
		columnName: "Tiền sau GD",
		columnValue: "amountAfterTransactions",
		isCopy: true,
	},
	{
		columnName: "Coin nhận",
		columnValue: "coinQuantity",
		isCopy: true,
	},
	{
		columnName: "Trạng thái",
		columnValue: "state",
		status: "Status",
	},
	{
		columnName: "Nội dung",
		columnValue: "description",
		isCopy: true,
		isMaxLength: true,
	},
	{
		columnName: "Actions",
		columnValue: "Actions",
		widget: Widget,
	},
];

const columnsSells = [
	{
		columnName: "Thời gian",
		columnValue: "createdAt",
		type: "Date",
	},
	{
		columnName: "Nhân viên",
		columnValue: "createdBy.name",
	},
	{
		columnName: "Nhân vật",
		columnValue: "userName",
		isCopy: true,
	},
	{
		columnName: "Game",
		columnValue: "gameId.name",
	},
	{
		columnName: "Bank",
		columnValue: "bankId.name",
	},
	{
		columnName: "Đơn giá",
		columnValue: "unitPrice",
	},
	{
		columnName: "Phí",
		columnValue: "rateCost",
	},
	{
		columnName: "Tiền nhận",
		columnValue: "transferAmount",
		isCopy: true,
	},
	{
		columnName: "Tiền sau GD",
		columnValue: "amountAfterTransactions",
		isCopy: true,
	},
	{
		columnName: "Coin chuyển",
		columnValue: "coinQuantity",
		isCopy: true,
	},
	{
		columnName: "Trạng thái",
		columnValue: "state",
		status: "Status",
	},
	{
		columnName: "Nội dung",
		columnValue: "description",
		isCopy: true,
		isMaxLength: true,
	},
	{
		columnName: "Actions",
		columnValue: "Actions",
		widget: Widget,
	},
];

const columnsPurchasesGame = [
	{
		columnName: "Thời gian",
		columnValue: "createdAt",
		type: "Date",
	},
	{
		columnName: "Nhân viên",
		columnValue: "createdBy.name",
	},
	{
		columnName: "Nhân vật",
		columnValue: "userName",
		isCopy: true,
	},
	{
		columnName: "Bank",
		columnValue: "bankId.name",
	},
	{
		columnName: "Đơn giá",
		columnValue: "unitPrice",
	},
	{
		columnName: "Phí",
		columnValue: "rateCost",
		type: "percen",
	},
	{
		columnName: "Tiền chuyển",
		columnValue: "transferAmount",
		isCopy: true,
	},
	{
		columnName: "Tiền sau GD",
		columnValue: "amountAfterTransactions",
		isCopy: true,
	},
	{
		columnName: "Coin nhận",
		columnValue: "coinQuantity",
		isCopy: true,
	},
	{
		columnName: "Trạng thái",
		columnValue: "state",
		status: "Status",
	},
	{
		columnName: "Nội dung",
		columnValue: "description",
		isCopy: true,
		isMaxLength: true,
	},
	{
		columnName: "Actions",
		columnValue: "Actions",
		widget: Widget,
	},
];

const columnsSellsGame = [
	{
		columnName: "Thời gian",
		columnValue: "createdAt",
		type: "Date",
	},
	{
		columnName: "Nhân viên",
		columnValue: "createdBy.name",
	},
	{
		columnName: "Nhân vật",
		columnValue: "userName",
		isCopy: true,
	},
	{
		columnName: "Bank",
		columnValue: "bankId.name",
	},
	{
		columnName: "Đơn giá",
		columnValue: "unitPrice",
	},
	{
		columnName: "Phí",
		columnValue: "rateCost",
	},
	{
		columnName: "Tiền nhận",
		columnValue: "transferAmount",
		isCopy: true,
	},
	{
		columnName: "Tiền sau GD",
		columnValue: "amountAfterTransactions",
		isCopy: true,
	},
	{
		columnName: "Coin chuyển",
		columnValue: "coinQuantity",
		isCopy: true,
	},
	{
		columnName: "Trạng thái",
		columnValue: "state",
		status: "Status",
	},
	{
		columnName: "Nội dung",
		columnValue: "description",
		isCopy: true,
		isMaxLength: true,
	},
	{
		columnName: "Actions",
		columnValue: "Actions",
		widget: Widget,
	},
];

const columnsThuChi = [
	{
		columnName: "Thời gian",
		columnValue: "createdAt",
		type: "Date",
	},
	{
		columnName: "Bank",
		columnValue: "targetBank.name",
	},
	{
		columnName: "Số tiền",
		columnValue: "amount",
		type: "thuchi",
	},
	{
		columnName: "Phí",
		columnValue: "cost",
	},
	{
		columnName: "Tiền trước giao dịch",
		columnValue: "amountBeforeTransfer",
	},
	{
		columnName: "Tiền sau giao dịch",
		columnValue: "amountAfterTransfer",
	},
	{
		columnName: "Người thực hiện",
		columnValue: "createdBy.name",
	},
	{
		columnName: "Note",
		columnValue: "description",
		isCopy: true,
		isMaxLength: true,
	},
	{
		columnName: "Actions",
		columnValue: "Actions",
		widget: Widget,
	},
];
const columnRoles = [
	{
		columnName: "Tên",
		columnValue: "name",
	},
	{
		columnName: "Các quyền",
		columnValue: "roles",
		type: "label",
	},
	{
		columnName: "Actions",
		columnValue: "Actions",
		widget: Widget,
	},
];
const columnBanks = [
	{
		columnName: "Tên",
		columnValue: "name",
	},
	{
		columnName: "Tiền",
		columnValue: "money",
	},
	{
		columnName: "Actions",
		columnValue: "Actions",
		widget: Widget,
	},
];

const columnUsers = [
	{
		columnName: "Tên",
		columnValue: "name",
	},
	{
		columnName: "Tên đăng nhập",
		columnValue: "accountName",
	},
	{
		columnName: "Chức vụ",
		columnValue: "permissions.name",
	},
	{
		columnName: "Actions",
		columnValue: "Actions",
		widget: Widget,
	},
];

const columnGames = [
	{
		columnName: "Tên",
		columnValue: "name",
	},
	{
		columnName: "Đơn vị tiền",
		columnValue: "coin_name",
	},
	{
		columnName: "Số tiền",
		columnValue: "coin_value",
	},
	{
		columnName: "Actions",
		columnValue: "Actions",
		widget: Widget,
	},
];
export {
	columnsProfit,
	columnsPurchases,
	columnUsers,
	columnRoles,
	columnBanks,
	columnGames,
	columnsPurchasesGame,
	columnsSells,
	columnsSellsGame,
	columnsThuChi,
};
