import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import PurchasePage from "./pages/PurchasePage";
import Sell from "./pages/Sell";
import ThuChi from "./pages/ThuChi";
import ProfilePage from "./pages/ProfilePage";
import BanksPage from "./pages/BanksPage";
import GamesPage from "./pages/GamesPage";
import RolePage from "./pages/RolePage";
import UserPage from "./pages/UserPage";
import { AlertContext, AlertProvider } from "./context/AlertContext";
import LoginPage from "./pages/LoginPage";
import { AuthenProvider } from "./context/AuthenContext";
import ConfigPage from "./pages/ConfigPage";
const router = createBrowserRouter([
	{
		path: "/",
		element: <HomePage></HomePage>,
	},
	{
		path: "/purchase",
		element: <PurchasePage></PurchasePage>,
	},
	{
		path: "/sell",
		element: <Sell></Sell>,
	},
	{
		path: "/thuchi",
		element: <ThuChi></ThuChi>,
	},
	{
		path: "/profile",
		element: <ProfilePage></ProfilePage>,
	},
	{
		path: "/roles",
		element: <RolePage></RolePage>,
	},
	{
		path: "/banks",
		element: <BanksPage></BanksPage>,
	},
	{
		path: "/games",
		element: <GamesPage></GamesPage>,
	},
	{
		path: "/users",
		element: <UserPage></UserPage>,
	},
	{
		path: "/login",
		element: <LoginPage></LoginPage>,
	},
	{
		path: "/config",
		element: <ConfigPage></ConfigPage>,
	},
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<AuthenProvider>
			<AlertProvider>
				<RouterProvider router={router} />
			</AlertProvider>
		</AuthenProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
