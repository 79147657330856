import React, { useRef } from "react";

const Dialog = ({ children, title, handleSubmitForm, handleClose }) => {
	const formRef = useRef(null);
	return (
		<>
			<div
				uib-modal-window="modal-window"
				className="modal fade ng-scope ng-isolate-scope in"
				role="dialog"
				index="0"
				animate="animate"
				tabindex="-1"
				uib-modal-animation-class="fade"
				modal-in-class="in"
				modal-animation="true"
				style={{ zIndex: 2000, display: "block" }}
			>
				<div className="modal-dialog ">
					<div className="modal-content" uib-modal-transclude="">
						<div className="modal-content ng-scope">
							<div className="modal-header">
								<button type="button" className="close" onClick={handleClose}>
									×
								</button>
								<h5 className="modal-title">{title}</h5>
							</div>

							<form
								onSubmit={handleSubmitForm}
								ref={formRef}
								className="ng-pristine ng-valid ng-valid-required"
							>
								<div
									className="modal-body"
									onKeyDown={(e) => {
										if (e.key === "Enter")
											if (formRef.current.reportValidity()) {
												handleSubmitForm(e);
											}
									}}
									tabIndex="0"
								>
									{children}
								</div>

								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-link"
										onClick={handleClose}
									>
										Đóng
									</button>
									<button type="submit" className="btn btn-primary">
										Xác nhận
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div
				uib-modal-backdrop="modal-backdrop"
				className="modal-backdrop fade ng-scope in"
				uib-modal-animation-class="fade"
				modal-in-class="in"
				modal-animation="true"
				data-bootstrap-modal-aria-hidden-count="1"
				aria-hidden="true"
				style={{ zIndex: 1040 }}
			></div>
		</>
	);
};

export default Dialog;
