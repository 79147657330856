import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import StatisticsOverview from "../components/StatisticsOverview";
import TableData from "../components/TableData";
import { columnsThuChi } from "../common/configColumns";
import Dialog from "../layouts/Dialog";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";
import { formatPriceNumber } from "../common/commonFunction";
import { AlertContext } from "../context/AlertContext";
import { AlertStatus } from "../common/enum";
import queryString from "query-string";
import moment from "moment";

const ThuChi = () => {
	const { addAlert } = useContext(AlertContext);
	const [tableData, setTableData] = useState([]);
	const [arrBank, setArrBank] = useState([]);
	const [currentItem, setCurrentItem] = useState({});
	const [isOpenTransactionForm, setIsOpenTransactionForm] = useState(false);
	const [isOpenChangeTransferMoneyForm, setIsOpenChangeTransferMoneyForm] =
		useState(false);

	useEffect(() => {
		handleGetData({
			fromDate: moment().format("YYYY-MM-DD"),
			toDate: moment().format("YYYY-MM-DD"),
		});
		handleGetAllBank();
	}, []);
	const handleGetAllBank = async () => {
		var res = await axiosClient.get(ServiceURL.banks);
		if (res.data && res.data.results) {
			setArrBank(res.data.results);
		}
	};
	const handleDelete = async (record) => {
		await axiosClient
			.delete(ServiceURL.transferMoneys + "/" + record._id)
			.then((res) => {
				addAlert("Thành công!", "Xóa giao dịch thành công");
				handleGetData();
				handleGetAllBank();
			})
			.catch(addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error));
	};
	const handleGetData = async (
		filter = {
			fromDate: moment().format("YYYY-MM-DD"),
			toDate: moment().format("YYYY-MM-DD"),
		}
	) => {
		let paramString =
			"?" +
			queryString.stringify({
				...filter,
			});
		const res = await axiosClient.get(ServiceURL.transferMoneys + paramString);
		if (res.data && res.data.results) {
			setTableData(res.data.results);
		}
	};
	const handleEdit = async (record) => {
		setIsOpenTransactionForm(true);
		setCurrentItem(record);
	};
	return (
		<MainLayout>
			<StatisticsOverview
				name={"Banks"}
				arrData={arrBank}
				isBoldHeader={true}
				fieldData={"money"}
				fieldHeader={"name"}
			></StatisticsOverview>
			<TableData
				columns={columnsThuChi}
				data={tableData}
				tableName={"Lịch sử thu chi"}
				options={{
					isIdx: true,
					isShowFilter: true,
					isThuChi: true,
					addTransaction: () => {
						if (arrBank.length < 2) {
							addAlert(
								"Thất bại!",
								"Bạn phải có ít nhất 2 ngân hàng để thực hiện giao dịch"
							);
						}
						setIsOpenTransactionForm(true);
					},
					transferMoney: () => {
						setIsOpenChangeTransferMoneyForm(true);
					},
					handleReload: handleGetData,
					handleDelete,
					handleEdit,
				}}
			></TableData>
			{isOpenTransactionForm && (
				<FormTransaction
					arrBank={arrBank}
					handleGetData={handleGetData}
					handleClose={() => {
						setIsOpenTransactionForm(false);
						setCurrentItem({});
						handleGetAllBank();
					}}
					dataEdit={currentItem}
					isEdit={!!currentItem && Object.keys(currentItem) > 0}
				/>
			)}
			{isOpenChangeTransferMoneyForm && (
				<FromTransferMoney
					arrBank={arrBank}
					handleGetData={handleGetData}
					handleClose={() => {
						setIsOpenChangeTransferMoneyForm(false);
						handleGetAllBank();
					}}
				/>
			)}
		</MainLayout>
	);
};

const FormTransaction = ({
	handleClose,
	arrBank,
	handleGetData,
	isEdit = false,
	dataEdit = {},
}) => {
	const { addAlert } = useContext(AlertContext);
	const [formState, setFormState] = useState(dataEdit);
	const handleSubmit = (event) => {
		event.preventDefault();
		if (isEdit) {
			axiosClient
				.patch(ServiceURL.transferMoneys + "/" + formState._id, {
					...formState,
					targetBank: formState.targetBank?.id || formState.targetBank,
					createdBy: formState.createdBy?.id || formState.createdBy,
				})
				.then((res) => {
					addAlert("Thành công!", "Thêm giao dịch thành công");
					setFormState({});
					handleGetData();
					handleClose();
				})
				.catch(addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error));
		} else {
			axiosClient
				.post(ServiceURL.transferMoneys, {
					...formState,
					targetBank: formState.targetBank || arrBank[0].id,
					type: formState.type || 0,
				})
				.then((res) => {
					addAlert("Thành công!", "Thêm giao dịch thành công");
					setFormState({});
					handleGetData();
					handleClose();
				})
				.catch(addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error));
		}
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState({ ...formState, [name]: value });
	};
	return (
		<Dialog
			handleClose={handleClose}
			handleSubmitForm={handleSubmit}
			title={"Thông tin giao dịch"}
		>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Ngân hàng</label>
					<div className="col-md-10">
						<select
							class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
							name="targetBank"
							value={formState.targetBank}
							onChange={handleChange}
						>
							{arrBank &&
								arrBank.map((item, idx) => {
									return (
										<option
											class="ng-binding ng-scope"
											key={item.id}
											value={item.id}
										>
											{item.name}: {formatPriceNumber(item.money || 0)}
										</option>
									);
								})}
						</select>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Loại giao dịch</label>
					<div className="col-md-10">
						<select
							class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
							name="type"
							value={formState.type}
							onChange={handleChange}
						>
							<option value="0" selected="selected">
								Thu
							</option>
							<option value="1">Chi</option>
						</select>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Số tiền</label>
					<div className="col-md-10">
						<input
							type="number"
							class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
							placeholder="Số tiền"
							required
							name="amount"
							value={formState.amount}
							onChange={handleChange}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 col-md-offset-2">
						<h5 className="text-success">
							{formatPriceNumber(formState.amount || 0)}
						</h5>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Phí</label>
					<div className="col-md-10">
						<input
							type="number"
							class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required"
							placeholder="Phí"
							required
							name="cost"
							value={formState.cost}
							onChange={handleChange}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 col-md-offset-2">
						<h5 className="text-success ng-binding">
							{formatPriceNumber(formState.cost || 0) || 0}
						</h5>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Ghi chú</label>
					<div className="col-md-10">
						<input
							type="text"
							class="form-control ng-pristine ng-untouched ng-valid ng-empty"
							placeholder="Ghi chú"
							required
							name="description"
							value={formState.description}
							onChange={handleChange}
						/>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

const FromTransferMoney = ({ handleClose, arrBank, handleGetData }) => {
	const { addAlert } = useContext(AlertContext);
	const [formState, setFormState] = useState({});
	const handleSubmit = (event) => {
		event.preventDefault();
		if (formState.targetBank === formState.fromBank) {
			addAlert(
				"Thất bại",
				"Bạn không thể chuyển cùng ngân hàng",
				AlertStatus.Error
			);
			return;
		}
		//Tách làm 2 giao dịch thu và chi cho ngân hàng nhận và gửi
		let objTake = {
			targetBank: formState.targetBank || arrBank[0].id,
			amount: formState.amount,
			cost: formState.costBy == 0 ? 0 : formState.cost,
			description: formState.description,
			type: 0,
		};
		let objGive = {
			targetBank: formState.fromBank || arrBank[0].id,
			amount: formState.amount,
			cost: formState.costBy == 0 ? formState.cost : 0,
			description: formState.description,
			type: 1,
		};
		axiosClient
			.post(ServiceURL.transferMoneys, objTake)
			.then((res) => {
				addAlert("Thành công!", "Thêm giao dịch thành công");
				setFormState();
				handleGetData();
				handleClose();
			})
			.catch((err) =>
				addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error)
			);

		axiosClient
			.post(ServiceURL.transferMoneys, objGive)
			.then((res) => {
				addAlert("Thành công!", "Thêm giao dịch thành công");
				setFormState();
				handleGetData();
				handleClose();
			})
			.catch((err) =>
				addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error)
			);
	};

	const handleChange = (e) => {
		const { name, value, type } = e.target;
		setFormState({
			...formState,
			[name]: type === "checkbox" ? e.target.checked : value,
		});
	};
	return (
		<Dialog
			handleClose={handleClose}
			title={"Thông tin giao dịch"}
			handleSubmitForm={handleSubmit}
		>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Ngân hàng gửi</label>
					<div className="col-md-10">
						<select
							class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
							name="fromBank"
							value={formState.fromBank}
							onChange={handleChange}
						>
							{arrBank &&
								arrBank.map((item) => {
									return (
										<option
											class="ng-binding ng-scope"
											key={item.id}
											value={item.id}
										>
											{item.name}: {formatPriceNumber(item.money || 0)}
										</option>
									);
								})}
						</select>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Ngân hàng nhận</label>
					<div className="col-md-10">
						<select
							class="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
							name="targetBank"
							value={formState.targetBank}
							onChange={handleChange}
						>
							{arrBank &&
								arrBank.map((item) => {
									return (
										<option
											class="ng-binding ng-scope"
											key={item.id}
											value={item.id}
										>
											{item.name}
										</option>
									);
								})}
						</select>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Số tiền</label>
					<div className="col-md-10">
						<input
							type="number"
							class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
							placeholder="Số tiền"
							name="amount"
							value={formState.amount}
							onChange={handleChange}
							required
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 col-md-offset-2">
						<h5 className="text-success ng-binding">
							{formatPriceNumber(formState.amount || 0)}
						</h5>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Phí</label>
					<div className="col-md-10">
						<input
							type="number"
							class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required"
							placeholder="Phí"
							name="cost"
							value={formState.cost}
							onChange={handleChange}
							required
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 col-md-offset-2">
						<h5 className="text-success ng-binding">
							{formatPriceNumber(formState.cost || 0) || 0}
						</h5>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Chịu phí</label>
					<div className="col-md-10">
						<label className="radio-inline">
							<div className="choice">
								<span
									ng-class="{'checked': data.feePay === 'from'}"
									class={`${formState.costBy == 0 ? "checked" : ""}`}
								>
									<input
										type="radio"
										class="styled ng-pristine ng-untouched ng-valid ng-not-empty"
										name="costBy"
										value={0}
										onChange={handleChange}
									/>
								</span>
							</div>
							Bên gửi
						</label>
						<label className="radio-inline">
							<div className="choice">
								<span
									ng-class="{'checked': data.feePay === 'to'}"
									class={`${formState.costBy == 1 ? "checked" : ""}`}
								>
									<input
										type="radio"
										class="styled ng-pristine ng-untouched ng-valid ng-not-empty"
										name="costBy"
										value={1}
										onChange={handleChange}
									/>
								</span>
							</div>
							Bên nhận
						</label>
					</div>
				</div>
			</div>
			<div className="form-group">
				<div className="row">
					<label className="control-label col-md-2">Ghi chú</label>
					<div className="col-md-10">
						<input
							type="text"
							class="form-control ng-pristine ng-untouched ng-valid ng-empty"
							placeholder={`Nội dung chuyển tiền`}
							ng-model="data.note"
							name="description"
							value={formState.description}
							onChange={handleChange}
						/>
					</div>
				</div>
			</div>
		</Dialog>
	);
};
export default ThuChi;
