import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";
import TableData from "../components/TableData";
import { columnGames } from "../common/configColumns";
import Dialog from "../layouts/Dialog";
import { AlertContext } from "../context/AlertContext";
import { AlertStatus } from "../common/enum";

const GamesPage = () => {
	const { addAlert } = useContext(AlertContext);
	useEffect(() => {
		handleGetData();
	}, []);

	const [formData, setFormData] = useState({});
	const [tableData, setTableData] = useState([]);
	const [isOpenEdit, setIsOpenEdit] = useState(false);
	const [objectEdit, setObjectEdit] = useState({});
	const handleSubmit = async (event) => {
		event.preventDefault();
		await axiosClient
			.post(ServiceURL.games, formData)
			.then((res) => {
				addAlert("Thành công!", "Thêm game thành công");
				handleGetData();
				setFormData({
					name: "",
					coin_name: "",
					coin_value: "",
				});
			})
			.catch(addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error));
	};
	const handleGetData = async () => {
		var res = await axiosClient.get(ServiceURL.games);
		if (res.data && res.data.results) {
			setTableData(res.data.results);
		}
	};

	const handleDelete = async (record) => {
		let isConfirm = window.confirm("Bạn có chắc chắn muốn xóa không ?");
		if (isConfirm)
			await axiosClient
				.delete(ServiceURL.games + "/" + record.id)
				.then((res) => {
					addAlert("Thành công!", "Xóa game thành công");
					handleGetData();
				})
				.catch((err) =>
					addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error)
				);
	};
	const handleEdit = async (record) => {
		setObjectEdit(record);
		setIsOpenEdit(true);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	return (
		<MainLayout>
			<div className="content-wrapper">
				<div className="col-md-6">
					<div className="panel panel-flat">
						<div className="panel-heading">
							<h5 className="panel-title">Thêm game</h5>
						</div>
						<div className="panel-body">
							<form className="form-horizontal" onSubmit={handleSubmit}>
								<fieldset className="content-group">
									<div className="form-group">
										<label className="control-label col-lg-2">Tên</label>
										<div className="col-lg-10">
											<input
												type="text"
												className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
												required
												value={formData.name}
												name="name"
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="control-label col-lg-2">
											Tên đơn vị tiền
										</label>
										<div className="col-lg-10">
											<input
												type="text"
												className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
												required
												value={formData.coin_name}
												name={"coin_name"}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<label className="control-label col-lg-2">Số tiền</label>
										<div className="col-lg-10">
											<input
												type="number"
												className="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
												required
												value={formData.coin_value}
												name="coin_value"
												onChange={handleChange}
											/>
										</div>
									</div>
								</fieldset>
								<div className="text-right">
									<button className="btn btn-primary">
										Thêm <i className="icon-arrow-right14 position-right"></i>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<TableData
						data={tableData}
						columns={columnGames}
						tableName={"Danh sách game"}
						options={{
							isIdx: true,
							isLoad: true,
							handleReload: handleGetData,
							handleDelete: handleDelete,
							handleEdit: handleEdit,
							scrollY: true,
						}}
					></TableData>
				</div>
			</div>
			{isOpenEdit && (
				<GameEdit
					handleClose={(isSubmit) => {
						setIsOpenEdit(false);
						setObjectEdit({});
						if (isSubmit) {
							handleGetData();
						}
					}}
					objectEdit={objectEdit}
				></GameEdit>
			)}
		</MainLayout>
	);
};

const GameEdit = ({ handleClose, objectEdit }) => {
	const { addAlert } = useContext(AlertContext);
	const handleSubmit = (e) => {
		e.preventDefault();
		axiosClient
			.patch(ServiceURL.games + "/" + formData.id, formData)
			.then((res) => {
				addAlert("Thành công!", "Xóa game thành công");
				handleClose(true);
			})
			.catch((err) => {
				addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error);
			});
	};
	const [formData, setFormData] = useState(objectEdit);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	return (
		<Dialog
			handleSubmitForm={handleSubmit}
			title={"Sửa thông tin game"}
			handleClose={handleClose}
		>
			<div className="form-group">
				<div className="row">
					<div className="col-sm-6">
						<label>Tên</label>
						<input
							type="text"
							placeholder="Tên"
							className="form-control "
							name="name"
							value={formData.name}
							onChange={handleChange}
						/>
					</div>

					<div className="col-sm-6">
						<label>Tên đơn vị tiền</label>
						<input
							type="text"
							placeholder="Số tiền"
							className="form-control "
							name="coin_name"
							value={formData.coin_name}
							onChange={handleChange}
							required
						/>
					</div>
					<div className="col-sm-6">
						<label>Số tiền</label>
						<input
							type="number"
							placeholder="Số tiền"
							className="form-control "
							name="coin_value"
							value={formData.coin_value}
							onChange={handleChange}
							required
						/>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default GamesPage;
