import React, { useContext, useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";
import Role from "../common/roles";
import TableData from "../components/TableData";
import { columnRoles } from "../common/configColumns";
import Dialog from "../layouts/Dialog";
import { AlertContext } from "../context/AlertContext";
import { AlertStatus } from "../common/enum";

const RolePage = () => {
	const { addAlert } = useContext(AlertContext);
	useEffect(() => {
		handleGetData();
	}, []);

	const [formData, setFormData] = useState({
		name: "",
		roles: [],
	});
	const [tableData, setTableData] = useState([]);
	const [isOpenEdit, setIsOpenEdit] = useState(false);
	const [objectEdit, setObjectEdit] = useState({});

	const handleSubmit = (event) => {
		event.preventDefault();
		let arrRole = new Set(formData.roles);
		axiosClient
			.post(ServiceURL.roles, {
				name: formData.name,
				roles: Array.from(arrRole),
			})
			.then((res) => {
				addAlert("Thành công!", "Thêm vai trò thành công");
				setFormData({
					name: "",
					roles: [],
				});
				handleGetData();
				document
					.querySelectorAll("input[type='checkbox']")
					.forEach((item) => (item.checked = false));
			})
			.catch((err) =>
				addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error)
			);
	};
	const handleGetData = async () => {
		var res = await axiosClient.get(ServiceURL.roles);
		if (res.data && res.data.results) {
			setTableData(res.data.results);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleDelete = async (record) => {
		let isConfirm = window.confirm("Bạn có chắc chắn muốn xóa không ?");
		if (isConfirm)
			await axiosClient
				.delete(ServiceURL.roles + "/" + record.id)
				.then((res) => {
					addAlert("Thành công!", "Xóa vai trò thành công");
				})
				.catch((err) =>
					addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error)
				);
		handleGetData();
	};
	const handleEdit = async (record) => {
		setObjectEdit(record);
		setIsOpenEdit(true);
	};
	return (
		<MainLayout>
			{/* <Alert message={"Thêm chức vụ thành công"} title={"Thành công"} /> */}
			<div className="content-wrapper">
				<div className="col-md-6">
					<div className="panel panel-flat">
						<div className="panel-heading">
							<h5 className="panel-title text-bold">Thêm chức vụ</h5>
						</div>
						<div className="panel-body">
							<form className="form-horizontal" onSubmit={handleSubmit}>
								<fieldset className="content-group">
									<legend className="text-bold"></legend>
									<div className="form-group">
										<label className="control-label col-lg-2">Tên gọi</label>
										<div className="col-lg-10">
											<input
												type="text"
												className="form-control ng-pristine ng-untouched ng-valid ng-empty"
												name="name"
												value={formData.name}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="form-group pt-15">
										<label className="control-label col-lg-2">Chọn quyền</label>
										<div className="col-lg-10">
											{Role.roles.map((item, idx) => {
												return (
													<div className="checkbox ng-scope" key={idx}>
														<label className="ng-binding">
															<input
																type="checkbox"
																className="styled ng-pristine ng-untouched ng-valid ng-empty"
																value={formData.roles[idx]}
																onChange={(event) => {
																	if (event.target.checked) {
																		setFormData((state) => {
																			if (state.roles) {
																				state.roles.push(item);
																			} else {
																				state.roles = [item];
																			}
																			return state;
																		});
																	} else {
																		setFormData((state) => {
																			const i = state.roles.indexOf(item);
																			if (i !== -1) {
																				state.roles.splice(i, 1);
																			}
																			return state;
																		});
																	}
																}}
															/>
															{item}
														</label>
													</div>
												);
											})}
										</div>
									</div>
								</fieldset>
								<div className="text-right">
									<button className="btn btn-primary">
										Thêm <i className="icon-arrow-right14 position-right"></i>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<TableData
						data={tableData}
						columns={columnRoles}
						tableName={"Danh sách chức vụ"}
						options={{
							isIdx: true,
							isLoad: true,
							handleReload: handleGetData,
							handleDelete: handleDelete,
							handleEdit: handleEdit,
						}}
					></TableData>
				</div>
			</div>
			{isOpenEdit && (
				<RoleEdit
					handleClose={(isSubmit) => {
						setIsOpenEdit(false);
						setObjectEdit({});
						if (isSubmit) {
							handleGetData();
						}
					}}
					objectEdit={objectEdit}
				></RoleEdit>
			)}
		</MainLayout>
	);
};

const RoleEdit = ({ handleClose, objectEdit }) => {
	const { addAlert } = useContext(AlertContext);
	const handleSubmit = (e) => {
		e.preventDefault();
		axiosClient
			.patch(ServiceURL.roles + "/" + formData.id, formData)
			.then((res) => {
				addAlert("Thành công!", "Sửa vai trò thành công");
				handleClose(true);
			})
			.catch((err) => {
				addAlert("Thất bại!", "Có lỗi xảy ra", AlertStatus.Error);
			});
	};
	const [formData, setFormData] = useState(objectEdit);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	return (
		<Dialog
			handleSubmitForm={handleSubmit}
			title={"Sửa thông tin chức vụ"}
			handleClose={handleClose}
		>
			<div className="form-group">
				<div className="row">
					<div className="col-sm-6">
						<label>Tên</label>
						<input
							type="text"
							className="form-control ng-pristine ng-untouched ng-valid ng-empty"
							name="name"
							value={formData.name}
							onChange={handleChange}
						/>
					</div>

					<div className="col-sm-6">
						<label>Chọn quyền</label>
						{Role.roles.map((item, idx) => {
							return (
								<div className="checkbox ng-scope" key={idx}>
									<label className="ng-binding">
										<input
											type="checkbox"
											className="styled ng-pristine ng-untouched ng-valid ng-empty"
											//value={formData.roles.includes(item)}
											checked={formData.roles.includes(item)}
											onChange={(event) => {
												if (event.target.checked) {
													setFormData((state) => {
														if (state.roles) {
															state.roles.push(item);
														} else {
															state.roles = [item];
														}
														return state;
													});
												} else {
													setFormData((state) => {
														const i = state.roles.indexOf(item);
														if (i !== -1) {
															state.roles.splice(i, 1);
														}
														return state;
													});
												}
											}}
										/>
										{item}
									</label>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default RolePage;
