import React, { useContext, useState } from "react";
import { axiosClient } from "../services/axiosClient";
import ServiceURL from "../services/ServiceURL";
import { AlertContext } from "../context/AlertContext";
import Alert from "../components/Alert";
import { AlertStatus } from "../common/enum";
import { useNavigate } from "react-router-dom";
import { AuthenContext } from "../context/AuthenContext";

const LoginPage = () => {
	const { addAlert, alerts } = useContext(AlertContext);
	const { user, token, login, logout, refreshAccessToken } =
		useContext(AuthenContext);
	const [formData, setFormData] = useState({});
	const navigate = useNavigate();
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const handleLogin = (e) => {
		e.preventDefault();
		axiosClient
			.post(ServiceURL.logIn, formData)
			.then((res) => {
				addAlert("Thành công!", "Đăng nhập thành công");
				setFormData({});
				login(res.data.user, res.data.tokens.access, res.data.tokens.refresh);
				// check quyền và nhảy trang
				navigate("/");
			})
			.catch((err) =>
				addAlert(
					"Thất bại!",
					"Tên đăng nhập hoặc mật khẩu không đúng",
					AlertStatus.Error
				)
			);
	};
	return (
		<div className="login-container  pace-done">
			{alerts.map((alert) => (
				<Alert
					key={alert.id}
					type={alert.type}
					message={alert.message}
					status={alert.status}
					title={alert.title}
				/>
			))}
			<div className="pace  pace-inactive">
				<div
					class="pace-progress"
					data-progress-text="100%"
					data-progress="99"
					style={{ transform: "translate3d(100%, 0px, 0px)" }}
				>
					<div className="pace-progress-inner"></div>
				</div>
				<div className="pace-activity"></div>
			</div>
			<div className="page-container ng-scope" ng-controller="login">
				<div className="page-content" ng-click="loginSubmit">
					<div className="content-wrapper">
						<div>
							<div
								class="panel panel-body login-form"
								style={{ width: "400px !important" }}
							>
								<div className="text-center">
									<div className="icon-object border-slate-300 text-slate-300">
										<i className="icon-reading"></i>
									</div>
									<h5 className="content-group">
										Đăng nhập{" "}
										<small className="display-block">
											Điền thông tin bên dưới
										</small>
									</h5>
								</div>
								<form
									onSubmit={handleLogin}
									class="ng-pristine ng-invalid ng-invalid-required"
								>
									<div className="form-group has-feedback has-feedback-left">
										<input
											type="text"
											name="name"
											value={formData.name}
											onChange={handleChange}
											class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
											placeholder="Username"
											required
										/>
										<div className="form-control-feedback">
											<i className="icon-user text-muted"></i>
										</div>
									</div>
									<div className="form-group has-feedback has-feedback-left">
										<input
											type="password"
											name="password"
											value={formData.password}
											onChange={handleChange}
											class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required"
											placeholder="Password"
											required
										/>
										<div className="form-control-feedback">
											<i className="icon-lock2 text-muted"></i>
										</div>
									</div>
									<div className="form-group">
										<button type="submit" className="btn btn-primary btn-block">
											Đăng nhập{" "}
											<i className="icon-circle-right2 position-right"></i>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
