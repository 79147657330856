import axios from "axios";
import { BASE_URL } from "./ServiceURL";

const axiosClient = axios.create({
	baseURL: BASE_URL,
	timeout: 15000,
});

axiosClient.interceptors.request.use(
	async (config) => {
		let tokenObject = localStorage.getItem("token")
			? JSON.parse(localStorage.getItem("token"))
			: "";
		config.headers.authorization = `Bearer ${tokenObject.token}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axiosClient.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response && error.response.status === 401) {
			// Chuyển trang login
			window.location.href = "/login";
		}
		return Promise.reject(error);
	}
);
const axiosClientFile = axios.create({
	baseURL: BASE_URL,
	timeout: 900000,
	//paramsSerializer: (params) => queryString.stringify(params),
	headers: {
		"Content-Type": "multipart/form-data",
	},
});

axiosClientFile.interceptors.request.use(
	async (config) => {
		config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
		config.responseType = "blob";
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export { axiosClient, axiosClientFile };
