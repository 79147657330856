import React from "react";
import { formatPriceNumber } from "../common/commonFunction";

const GameInfo = ({ data, gameName, gameInfo, isPurchased = false }) => {
	return (
		<div
			className="tab-content ng-scope"
			style={{ background: "#fff", marginBottom: 20 }}
		>
			<div className="tab-pane active">
				<div className="row">
					<div className="page-header">
						<div className="page-header-content">
							<div className="col-md-3">
								<div className="page-title">
									<ul className="list-inline">
										<li style={{ verticalAlign: "top", paddingTop: 11 }}>
											<a
												href="#"
												className="btn btn-flat btn-rounded btn-icon btn-xs valign-text-bottom border-teal text-teal"
												ng-class="{'border-teal': mode !== 'sell', 'text-teal': mode !== 'sell', 'border-warning-400': mode === 'sell', 'text-warning-400': mode === 'sell'}"
											>
												<i className="icon-bag"></i>
											</a>
										</li>
										<li className="text-left">
											<h4>
												<span className="text-semibold ng-binding">
													{isPurchased ? "Mua" : "Bán"}
												</span>{" "}
												-{" "}
												<span
													ng-class="{'border-teal': mode !== 'sell', 'text-teal': mode !== 'sell', 'border-warning-400': mode === 'sell', 'text-warning-400': mode === 'sell'}"
													className="ng-binding border-teal text-teal"
												>
													{gameInfo?.name}
												</span>
											</h4>
											<h5 className="ng-binding">
												<span className="text-primary ng-binding">
													{formatPriceNumber(gameInfo?.coin_value || 0)}
												</span>{" "}
												{gameInfo?.coin_name}
											</h5>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-9">
								<div className="col-xs-3">
									<div className="page-title">
										<h4>
											<span className="text-semibold ng-binding">
												{isPurchased ? "Tiền chuyển" : "Tiền nhận"}
											</span>
										</h4>
										<h5>
											<span className="text-primary ng-binding">
												{formatPriceNumber(data?.totalAmountTransfer || 0)}
											</span>
										</h5>
									</div>
								</div>
								<div className="col-xs-3">
									<div className="page-title">
										<h4>
											<span className="text-semibold ng-binding">
												{isPurchased ? "Coin nhận" : "Coin chuyển"}
											</span>
										</h4>
										<h5 className="ng-binding">
											<span className="text-primary ng-binding">
												{formatPriceNumber(data?.totalCoinTransfer || 0)}
											</span>{" "}
											{gameInfo?.coin_name}
										</h5>
									</div>
								</div>
								<div className="col-xs-3">
									<div className="page-title">
										<h4>
											<span className="text-semibold ng-binding">
												{isPurchased
													? "Tiền chuyển / Coin nhận C1"
													: "Tiền nhận / Coin chuyển C1"}
											</span>
										</h4>
										<h5 className="ng-binding">
											<span className="text-primary ng-binding">
												{formatPriceNumber(data?.totalAmountTransferC1 || 0)} /
												{formatPriceNumber(data?.totalCoinTransferC1 || 0)}
											</span>{" "}
											{gameInfo?.coin_name}
										</h5>
									</div>
								</div>
								<div className="col-xs-3">
									<div className="page-title">
										<h4>
											<span className="text-semibold">Lợi nhuận</span>
										</h4>
										<h5>
											<span className="text-primary ng-binding">
												{data?.profit || 0}
											</span>
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GameInfo;
